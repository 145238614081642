import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import DeliveryNoteHeader from '../../DeliveryNoteHeader';

export default function RelayDeliveryNoteHeader({ ordersLen, relay }) {
  const {
    deliveryDate,
    depositSchedule,
    deliverySchedule,
    title,
    city,
    zipCode,
    address,
    relayPhone,
    comment,
    complement,
    labelsLen,
  } = relay;

  return (
    <DeliveryNoteHeader
      FirstSection={
        <FirstSection params={{ ordersLen, city, zipCode, deliveryDate, deliverySchedule, depositSchedule }} />
      }
      SecondSection={<SecondSection params={{ title, address, city, zipCode, relayPhone, complement, comment }} />}
      ThirdSection={<ThirdSection params={{ labelsLen }} />}
    />
  );
}
