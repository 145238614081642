import React from 'react';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { CameraAlt as CameraAltIcon, Label as LabelIcon } from '@material-ui/icons';

import { ProductSizeChip } from '../../Product/Chip/ProductSizeChip';
import { formatPrice } from '../../../../shared/utils/format-price';

const HEADERS = [
  {
    key: 'photo',
    align: 'left',
    label: '',
  },
  {
    key: 'name',
    align: 'left',
    label: 'Nom',
  },
  {
    key: 'category',
    align: 'left',
    label: 'Catégorie',
  },
  {
    key: 'size',
    align: 'left',
    label: 'Taille',
  },
  {
    key: 'noLabel',
    align: 'left',
    label: 'Étiquette',
  },
  {
    key: 'quantity',
    align: 'center',
    label: 'Quantité',
  },
  {
    key: 'price',
    align: 'right',
    label: 'Prix',
  },
  {
    key: 'total',
    align: 'right',
    label: 'Total',
  },
];

export default function OrderItemsTable({ items }) {
  const footerStyle = { fontSize: 14, fontWeight: 'bold' };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {HEADERS.map((header) => (
              <TableCell key={header.key} align={header.align} style={{ fontWeight: 'bold' }}>
                {header.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map((item, index) => (
            <TableRow key={`${item.id}-${index}`}>
              <TableCell align="left">
                {item.productId && (
                  <img
                    src={`${process.env.REACT_APP_AWS}/${item.photo}`}
                    alt={`product-${item.ref}`}
                    width={50}
                    height={50}
                  />
                )}
                {!item.productId && <CameraAltIcon style={{ fontSize: 50 }} />}
              </TableCell>
              <TableCell align="left">{item.name}</TableCell>
              <TableCell align="left">{item.category}</TableCell>
              <TableCell align="left">
                <ProductSizeChip size={item.size}></ProductSizeChip>
              </TableCell>
              <TableCell align="left">
                <LabelIcon color={item.noLabel ? 'secondary' : 'primary'} />
              </TableCell>
              <TableCell align="center">{item.quantity}</TableCell>
              <TableCell align="right">{formatPrice(item.price)}</TableCell>
              <TableCell align="right">{formatPrice(item.quantity * item.price)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={7} align="right" style={footerStyle}>
              Total
            </TableCell>
            <TableCell align="right" style={footerStyle}>
              {formatPrice(items.reduce((acc, item) => acc + item.price * item.quantity, 0))}
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
