import React, { useEffect, useMemo } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import { FormControl, InputLabel, Select } from '@material-ui/core';

import { useGetRelays } from '../../../shared/relays/hooks/relay.hook';
import capitalizeFirstLetter from '../../../shared/utils/capitalize-first-letter';

export default function RelaySelect({ segmentId, selectedValue, setSelectedValue }) {
  const { loading, relays } = useGetRelays({ segmentId });

  const formattedRelays = useMemo(() => {
    if (!relays) return null;

    return relays.reduce((acc, item) => {
      const firstLetter = item.name[0].toUpperCase();
      if (!acc[firstLetter]) acc[firstLetter] = [];
      acc[firstLetter].push({ _id: item._id, name: item.name });
      return acc;
    }, {});
  }, [relays]);

  const handleChange = (event) => {
    if (event.target.value === 'all') return setSelectedValue(undefined);
    setSelectedValue(event.target.value);
  };

  return (
    <>
      {loading && <Skeleton variant="rect" width={125} height={55} />}
      {!loading && formattedRelays && (
        <FormControl variant="outlined" style={{ minWidth: '125px', maxWidth: '250px' }}>
          <InputLabel>Point de livraison</InputLabel>
          <Select native label="Point de livraison" value={selectedValue} onChange={handleChange}>
            <optgroup label="Autre">
              <option value="all">Tous les points de livraison</option>
            </optgroup>
            {Object.keys(formattedRelays).map((firstLetter) => (
              <optgroup key={`group-${firstLetter}`} label={firstLetter}>
                {formattedRelays[firstLetter].map((relay) => (
                  <option key={relay._id} value={relay._id}>
                    {capitalizeFirstLetter(relay.name.toLocaleLowerCase())}
                  </option>
                ))}
              </optgroup>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}
