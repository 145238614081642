import React, { useState } from 'react';

import { Button, FormControl, InputLabel, Paper, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LastMonthsSelect from '../../../../components/shared/Select/LastMonthsSelect';
import AdvStatusSelect from '../../../../components/Order/AdvStatus/Select/AdvStatusSelect';
import SegmentSelect from '../../../../components/Segment/Select/SegmentSelect';
import RelaySelect from '../../../../components/Relay/Select/RelaySelect';
import useGetCommissionsStats from '../../../../shared/statistics/hooks/use-get-commissions-stats.hook';

const useStyles = makeStyles({
  root: {
    '& > h1': {
      margin: '0',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filter: {
    padding: '1rem',

    '& > div': {
      display: 'flex',
      gap: '1rem',
    },

    '& > h4': {
      margin: '0 0 1rem 0',
    },
  },
});

export default function AdminStatisticsCommissionPage() {
  const classes = useStyles();

  const [selectedDateType, setSelectedDateType] = useState('delivery');
  const [selectedMonth, setSelectedMonth] = useState(undefined);
  const [selectedAdvStatus, setSelectedAdvStatus] = useState('archived');
  const [selectedRelayId, setSelectedRelayId] = useState(undefined);
  const [selectedSegmentId, setSelectedSegmentId] = useState(undefined);

  const handleDateTypeChange = (event) => setSelectedDateType(event.target.value);

  const handleChangeSegment = (value) => {
    setSelectedRelayId(undefined);
    setSelectedSegmentId(value);
  };

  const { loading, commissions } = useGetCommissionsStats({
    dateType: selectedDateType,
    month: selectedMonth,
    advStatus: selectedAdvStatus,
    relayId: selectedRelayId,
    segmentId: selectedSegmentId,
  });

  return (
    <>
      <section className={classes.root}>
        <div className={classes.header}>
          <h1>Commissions</h1>
          <Button variant="contained" color="primary">
            Imprimer les factures
          </Button>
        </div>
        <Paper className={classes.filter}>
          <h4>Filtrer les commandes :</h4>
          <div>
            <FormControl variant="outlined">
              <InputLabel>Date à utiliser</InputLabel>
              <Select native value={selectedDateType} label="Date à utiliser" onChange={handleDateTypeChange}>
                <option value="delivery">Date de livraison</option>
                <option value="created">Date de commande</option>
              </Select>
            </FormControl>
            <LastMonthsSelect take={24} selectedValue={selectedMonth} setSelectedValue={setSelectedMonth} />
            <AdvStatusSelect selectedValue={selectedAdvStatus} setSelectedValue={setSelectedAdvStatus} />
            <SegmentSelect selectedValue={selectedSegmentId} setSelectedValue={handleChangeSegment} />
            {selectedSegmentId && (
              <RelaySelect
                segmentId={selectedSegmentId}
                selectedValue={selectedRelayId}
                setSelectedValue={setSelectedRelayId}
              />
            )}
          </div>
        </Paper>
        <Paper></Paper>
      </section>
    </>
  );
}
