import React, { useEffect, useMemo } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import { FormControl, InputLabel, Select } from '@material-ui/core';

import { useFetchSegments } from '../../../shared/segments/hooks/segment.hook';
import capitalizeFirstLetter from '../../../shared/utils/capitalize-first-letter';

export default function SegmentSelect({ selectedValue, setSelectedValue }) {
  const { segments } = useFetchSegments();
  const formattedSegments = useMemo(() => {
    if (!segments) return segments;
    return segments.reduce((acc, item) => {
      const firstLetter = item.name[0].toUpperCase();
      if (!acc[firstLetter]) acc[firstLetter] = [];
      acc[firstLetter].push(item);
      return acc;
    }, {});
  }, [segments]);

  useEffect(() => {
    if (!selectedValue && segments) setSelectedValue(segments[0]._id);
  }, [selectedValue, segments]);

  const handleChange = (event) => setSelectedValue(event.target.value);

  return (
    <>
      {!segments && <Skeleton variant="rect" width={125} height={55} />}
      {segments && (
        <FormControl variant="outlined" style={{ maxWidth: '250px' }}>
          <InputLabel>Segment</InputLabel>
          <Select native label="Segment" onChange={handleChange} value={selectedValue}>
            {Object.keys(formattedSegments).map((firstLetter) => (
              <optgroup key={`group-${firstLetter}`} label={firstLetter}>
                {formattedSegments[firstLetter].map((segment) => (
                  <option key={segment._id} value={segment._id}>
                    {capitalizeFirstLetter(segment.name.toLocaleLowerCase())}
                  </option>
                ))}
              </optgroup>
            ))}
          </Select>
        </FormControl>
      )}
    </>
  );
}
