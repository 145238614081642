import React from 'react';

import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import DeliveryNoteHeader from '../../DeliveryNoteHeader';

export default function HomeDeliverySectionHeader({ order }) {
  const { owner, deliveryInfos } = order;
  const { deliveryAddress, deliveryDate, deliverySchedule } = deliveryInfos;
  const { city, zipCode, address, complement } = deliveryAddress;
  const { firstname, lastname, phoneNumber: phone, information } = owner;

  const fullname = `${lastname} ${firstname}`;

  return (
    <DeliveryNoteHeader
      FirstSection={<FirstSection params={{ city, zipCode, deliveryDate, deliverySchedule }} />}
      SecondSection={<SecondSection params={{ fullname, address, city, zipCode, phone, complement, information }} />}
      ThirdSection={<ThirdSection params={{ labelsLen: order.labelsLen }} />}
    />
  );
}
