import React, { useEffect, useState, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import Cookies from 'js-cookie';

import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons/';

import {
  Grid,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Collapse,
  Box,
  Link,
  Badge,
} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';

import { calcTotalOrder } from '../../../utils/totalOrders';
import currencyFormater from '../../../utils/currencyFormater';
import { ADMIN_ORDER_APP_PATHS } from '../../../shared/order/constants/path.constants';

const useStyles = makeStyles((theme) => ({
  invit: {
    maxWidth: '285px',
    minWidth: '200px',
    padding: '1rem',
    margin: '10px',
    border: '2px solid #f05c35',
    borderRadius: '20px',
  },
  btn_orange: {
    backgroundColor: '#f05c33',
    color: 'white',
    padding: '0.5rem',
    '&:hover': {
      color: '#9e9e9e',
    },
  },
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'fit-content',
  },
  paper: {
    padding: theme.spacing(2),
  },
  h2: {
    margin: 0,
  },
}));

export default function InvitationDetails() {
  const classes = useStyles();
  const { id } = useParams();
  const [state, setState] = useState({
    invitation: null,
    orders: [],
    total: {
      products: 0,
      discount: 0,
      payed: 0,
      validated: 0,
    },
  });
  const [open, setOpen] = useState(false);

  const columns = [
    { id: '', label: '', minWidth: 50, align: 'left' },
    { id: 'id', label: 'ID', minWidth: 50, align: 'left' },
    { id: 'client', label: 'Client', minWidth: 100, align: 'left' },
    {
      id: 'statut',
      label: 'Statut',
      minWidth: 100,
      align: 'center',
    },
    { id: 'adv', label: 'ADV', minWidth: 100, align: 'center' },
    {
      id: 'amount',
      label: 'Montant Produits',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'discount',
      label: 'Réduction',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'payedAmount',
      label: 'Montant Payé',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'creationDate',
      label: 'Date de création',
      minWidth: 100,
      align: 'center',
    },
  ];

  const calcTotal = (orders) => {
    // console.log(orders);

    if (orders.length === 0) {
      return 0;
    }

    const total = orders.reduce(
      (acc, curr) => acc + curr.items.reduce((acc, curr) => acc + curr.price * curr.quantity, 0),
      0
    );

    return total;
  };

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('jwt');
      const invitation = await axios
        .get(`${process.env.REACT_APP_API}/invitations/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data.data.invitation)
        .catch((err) => {
          console.log(err);
        });

      const orders = await axios
        .get(`${process.env.REACT_APP_API}/invitations/orders/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data.data.orders)
        .catch((err) => {
          console.log(err);
        });

      const totalPayed = orders
        .filter((order) => order.status === 'paid')
        .reduce((acc, curr) => acc + calcTotalOrder(curr).formatTotalTtc, 0);

      setState((prev) => ({
        ...prev,
        total: {
          ...prev.total,
          payed: currencyFormater(totalPayed),
        },
      }));

      const totalDiscounts = orders.reduce((acc, curr) => {
        if (curr.discount) {
          if (curr.discount.reduction === 'flat') {
            return acc + curr.discount.value;
          }

          return (
            acc +
            (curr.items.reduce((accu, curre) => accu + curre.quantity * curre.price, 0) * curr.discount.value) / 100
          );
        }

        return 0;
      }, 0);
      const totalInvitations = calcTotal(orders);
      const validated = calcTotal(orders.filter((order) => order.status === 'paid'));

      console.log(validated);

      const totalValidated = validated === 0 ? validated : validated - totalDiscounts;

      // const totalPayed = totalInvitations - totalDiscounts;

      setState((prev) => ({
        ...prev,
        invitation,
        orders,
        total: {
          ...prev.total,
          products: currencyFormater(totalInvitations),
          discount: currencyFormater(totalDiscounts),
          // payed: currencyFormater(totalPayed),
          validated: currencyFormater(totalValidated),
        },
      }));
    };
    fetchData();
  }, [id]);

  return (
    <div className={classes.container}>
      <h1>Invitation : {id}</h1>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <h2 className={classes.h2}>Informations</h2>
            {state.invitation ? (
              <div>
                <p>
                  <b>Eco-acteur : </b>
                  <Link href={`/admin/users/${state.invitation.sender.id}`}>{state.invitation.sender.fullname}</Link>
                </p>
                <p>
                  <b>Date de création : </b>
                  {moment(state.invitation.createdAt).format('LL')}
                </p>
                <p>
                  <b>Date de livraison : </b>
                  {moment(state.invitation.deliveryInfos.deliveryDate).format('LL')}
                </p>
                <p>
                  <b>Participants : </b>
                  {state.orders.length} / {state.invitation.contacts.length}
                </p>
              </div>
            ) : null}
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <h2 className={classes.h2}>Paiement</h2>
            {state.invitation ? (
              <div>
                <p>
                  <b>Total payé : </b>
                  {state.total.payed}
                </p>
                <p>
                  <b>Nombre de commandes payées : </b>
                  {state.orders.filter((order) => order.status === 'paid').length}
                </p>
                <p>
                  <b>Nombre de commandes non payées : </b>
                  {state.orders.filter((order) => order.status === 'waiting').length}
                </p>
              </div>
            ) : null}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((el, idx) => (
                    <TableCell key={idx} align={el.align}>
                      {el.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {state.orders.length > 0
                  ? state.orders.map((order, idx) => (
                      <>
                        <TableRow key={idx}>
                          <TableCell>
                            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <Link href={ADMIN_ORDER_APP_PATHS.ORDER_SUMMARY.replace(':orderId', order.id)}>
                              {order.id.slice(-5)}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <Link href={`/admin/users/${order.owner.id}`}>{order.owner.fullname}</Link>
                          </TableCell>
                          <TableCell align="center">
                            <Badge
                              badgeContent={
                                order.status === 'paid' ? 'Payé' : order.status === 'waiting' ? 'Attente' : 'Impayé'
                              }
                              color={
                                order.status === 'paid' ? 'primary' : order.status === 'waiting' ? 'secondary' : 'error'
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Badge
                              badgeContent={
                                order.advStatus === 'input'
                                  ? 'Saisie'
                                  : order.advStatus === 'validate'
                                  ? 'Validée'
                                  : order.advStatus === 'processed'
                                  ? 'Traitée'
                                  : order.advStatus === 'archived'
                                  ? 'Archivée'
                                  : 'Annulée'
                              }
                              color={
                                order.advStatus === 'archived'
                                  ? 'primary'
                                  : order.advStatus === 'canceled'
                                  ? 'secondary'
                                  : 'error'
                              }
                            />
                          </TableCell>
                          <TableCell align="center">{currencyFormater(calcTotalOrder(order).formatTotalTtc)}</TableCell>
                          <TableCell align="center">
                            {order.discount ? order.discount.value : <Badge badgeContent="non" color="error" />}
                            {order.discount && (order.discount.reduction === 'flat' ? '€' : '%')}
                          </TableCell>
                          <TableCell align="center">
                            {currencyFormater(
                              calcTotalOrder(order).formatTotalTtc -
                                calcTotalOrder(order).formatTotalCompanyDiscountAmount -
                                calcTotalOrder(order).formatTotalDiscountAmount
                            )}
                          </TableCell>
                          <TableCell align="center">{moment(order.createdAt).format('LLL')}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                              <Box margin={1}>
                                <Typography variant="h6" component="div">
                                  Détails de la commande :
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Nom</TableCell>
                                      <TableCell>Rubrique</TableCell>
                                      <TableCell>Sous-rubrique</TableCell>
                                      <TableCell align="center">Quantité</TableCell>
                                      <TableCell align="center">Prix</TableCell>
                                      <TableCell align="center">Prix total</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {order.items.map((item, idx) => (
                                      <TableRow key={idx}>
                                        <TableCell>
                                          <Link href={`/admin/products/${item._id}`}>{item.name}</Link>
                                        </TableCell>
                                        <TableCell>{item.category}</TableCell>
                                        <TableCell>{item.subCategory}</TableCell>
                                        <TableCell align="center">{item.quantity}</TableCell>
                                        <TableCell align="center">{currencyFormater(item.price)}</TableCell>
                                        <TableCell align="center">
                                          {currencyFormater(item.price * item.quantity)}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </>
                    ))
                  : null}
              </TableBody>
            </Table>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
