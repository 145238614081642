import React from 'react';

import AdminOrdersRoutes from './orders.routes';
import Back from '../../layouts/Back';
import Dashboard from '../../components/Dashboard';
import AppRoute from '../../shared/routes/AppRoute';
import Commission from '../../views/Back/Commission/Commission';
import UserList from '../../views/Back/User/UserList';
import CloseWebsite from '../../views/Back/CloseWebsite/CloseWebsite';
import AnalyticsDashboard from '../../views/Back/Analytics/AnalyticsDashboard';
import OrderStats from '../../views/Back/Analytics/orderStats';
import UserProfil from '../../views/Back/User/UserProfil';
import UserCreateOrderPage from '../../views/Back/User/createOrder/Page';
import CreateUser from '../../views/Back/User/CreateUser';
import BasketList from '../../views/Back/Basket/BasketList';
import CreateBasket from '../../views/Back/Basket/CreateBasket';
import BasketPage from '../../views/Back/Basket/BasketPage';
import ProductList from '../../views/Back/Product/ProductList';
import CreateProduct from '../../views/Back/Product/CreateProduct';
import ProductPage from '../../views/Back/Product/ProductPage';
import CategoryList from '../../views/Back/Category/CategoryList';
import CategoryDetails from '../../views/Back/Category/CategoryDetails';
import SubCategoryList from '../../views/Back/SubCategory/SubCategoryList';
import SubCategoryDetails from '../../views/Back/SubCategory/SubCategoryDetails';
import RelayPointList from '../../views/Back/Relay/RelayPointList';
import RelayPointDetails from '../../views/Back/Relay/RelayPointDetails';
import CreateRelay from '../../views/Back/Relay/CreateRelay';
import DeliveryCityList from '../../views/Back/DeliveryCity/DeliveryCityList';
import DeliveryCityDetails from '../../views/Back/DeliveryCity/DeliveryCityDetails';
import DeliveryZoneList from '../../views/Back/DeliveryZone/DeliveryZoneList';
import DeliveryZoneDetails from '../../views/Back/DeliveryZone/DeliveryZoneDetails';
import DeliveryServicesList from '../../views/Back/DeliveryService/deliveryServicesList';
import DeliveryServicesDetails from '../../views/Back/DeliveryService/deliveryServicesDetails';
import UpdateOrder from '../../views/Back/Order/UpdateOrder';
import DeliveryNotes from '../../views/Back/DeliveryNotes/DeliveryNotesList';
import BillingList from '../../views/Back/Billing/BillingList';
import DiscountList from '../../views/Back/Discount/DiscountList';
import DiscountDetails from '../../views/Back/Discount/DiscountDetails';
import TaxeList from '../../views/Back/Taxe/TaxeList';
import SegmentList from '../../views/Back/Segment/SegmentList';
import SegmentDetails from '../../views/Back/Segment/SegmentDetails';
import InvitationsDashboard from '../../views/Back/Invitation/InvitationsDashboard';
import InvitationDetails from '../../views/Back/Invitation/InvitationDetails';
import EcoStatistics from '../../views/Back/Invitation/EcoStatistics';
import WeightList from '../../views/Back/Weight/WeightList';
import TeamShow from '../../views/Back/Team/TeamShow';
import ProducersList from '../../views/Back/Producers/ProducersList';
import BannersList from '../../views/Back/Banner/BannersList';
import PageHome from '../../views/Back/PageHome';
import TagList from '../../views/Back/Tag/TagList';
import SubscriberList from '../../views/Back/Subscriber/SubscriberList';
import AdminDashboardPage from '../../views/admin/dashboard/Page';
import AdminStatisticsRoutes from './statistics.routes';

export default function AdminRoutes() {
  return (
    <>
      <AppRoute exact path="/admin" layout={Back} component={Dashboard} />

      <AppRoute exact path="/admin/dashboard" layout={Back} component={AdminDashboardPage} />

      <AppRoute exact path="/admin/commission" layout={Back} component={Commission} />
      <AppRoute exact path="/admin/users/list/:query" layout={Back} component={UserList} />
      <AppRoute exact path="/admin/closeWebsite" layout={Back} component={CloseWebsite} />
      <AppRoute exact path="/admin/analytics" layout={Back} component={AnalyticsDashboard} />
      <AppRoute exact path="/admin/stats" layout={Back} component={OrderStats} />
      <AppRoute exact path="/admin/users/:id" layout={Back} component={UserProfil} />
      <AppRoute exact path="/admin/users/createOrder/:id" layout={Back} component={UserCreateOrderPage} />
      <AppRoute exact path="/admin/newUser" layout={Back} component={CreateUser} />
      <AppRoute exact path="/admin/baskets/list" layout={Back} component={BasketList} />
      <AppRoute exact path="/admin/baskets/create" layout={Back} component={CreateBasket} />
      <AppRoute exact path="/admin/baskets/:basketId" layout={Back} component={BasketPage} />
      <AppRoute exact path="/admin/products/list" layout={Back} component={ProductList} />
      <AppRoute exact path="/admin/products/create" layout={Back} component={CreateProduct} />
      <AppRoute path="/admin/products/:id" layout={Back} component={ProductPage} />
      <AppRoute exact path="/admin/categories" layout={Back} component={CategoryList} />
      <AppRoute exact path="/admin/categories/:id" layout={Back} component={CategoryDetails} />
      <AppRoute exact path="/admin/subcategories" layout={Back} component={SubCategoryList} />
      <AppRoute exact path="/admin/subcategories/:id" layout={Back} component={SubCategoryDetails} />
      <AppRoute exact path="/admin/relayPoints" layout={Back} component={RelayPointList} />
      <AppRoute exact path="/admin/relayPoints/:id" layout={Back} component={RelayPointDetails} />
      <AppRoute exact path="/admin/createRelay" layout={Back} component={CreateRelay} />
      <AppRoute exact path="/admin/deliveryCities" layout={Back} component={DeliveryCityList} />
      <AppRoute exact path="/admin/deliveryCities/:id" layout={Back} component={DeliveryCityDetails} />
      <AppRoute exact path="/admin/deliveryZones" layout={Back} component={DeliveryZoneList} />
      <AppRoute exact path="/admin/deliveryZones/:id" layout={Back} component={DeliveryZoneDetails} />
      <AppRoute exact path="/admin/deliveryServices" layout={Back} component={DeliveryServicesList} />
      <AppRoute exact path="/admin/deliveryServices/:id" layout={Back} component={DeliveryServicesDetails} />
      <AppRoute exact path="/admin/orders/updateOrder/:id" layout={Back} component={UpdateOrder} />
      <AppRoute path="/admin/deliveryNotes" layout={Back} component={DeliveryNotes} />
      <AppRoute path="/admin/billings" layout={Back} component={BillingList} />
      <AppRoute exact path="/admin/discounts/list/:query" layout={Back} component={DiscountList} />
      <AppRoute exact path="/admin/discounts/:id" layout={Back} component={DiscountDetails} />
      <AppRoute exact path="/admin/taxes" layout={Back} component={TaxeList} />
      <AppRoute exact path="/admin/segments" layout={Back} component={SegmentList} />
      <AppRoute exact path="/admin/segments/:id" layout={Back} component={SegmentDetails} />
      <AppRoute exact path="/admin/invitations" layout={Back} component={InvitationsDashboard} />
      <AppRoute exact path="/admin/invitation/:id" layout={Back} component={InvitationDetails} />
      <AppRoute exact path="/admin/statistics" layout={Back} component={EcoStatistics} />
      <AppRoute exact path="/admin/weight" layout={Back} component={WeightList} />
      <AppRoute exact path="/admin/teams" layout={Back} component={TeamShow} />
      <AppRoute exact path="/admin/producers" layout={Back} component={ProducersList} />
      <AppRoute exact path="/admin/banners" layout={Back} component={BannersList} />
      <AppRoute exact path="/admin/pageHome" layout={Back} component={PageHome} />
      <AppRoute exact path="/admin/tags" layout={Back} component={TagList} />
      <AppRoute exact path="/admin/subscribers/:type/management" layout={Back} component={SubscriberList} />
      <AdminOrdersRoutes />
      <AdminStatisticsRoutes />
    </>
  );
}
