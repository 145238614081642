import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import RelayDeliveryNoteHeader from './header/RelayDeliveryNoteHeader';
import DeliveryNoteList from '../DeliveryNoteList';

const useDeliverySectionStyle = makeStyles({
  root: {
    pageBreakInside: 'avoid',
    pageBreakAfter: 'always',
    marginBottom: '1.5rem',
  },
});
export default function RelayDeliveryNoteSection({ delivery }) {
  const classes = useDeliverySectionStyle();
  const { relay, orders } = delivery;

  return (
    <section className={classes.root}>
      <RelayDeliveryNoteHeader relay={relay} ordersLen={orders.length} />
      <DeliveryNoteList orders={orders} />
    </section>
  );
}
