import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import React from 'react';
import { formatPrice } from '../../../shared/utils/format-price';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflow: 'auto',

    '& > h3': {
      margin: '0 0 1rem 0',
    },
  },

  quantityController: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: '140px',
    gap: '0.5rem',

    '& > button': {
      minWidth: '24px',
      maxHeight: '24px',
    },
  },
}));

const TABLE_HEADER_CELLS = [
  {
    key: 'name',
    label: 'Nom',
    align: 'left',
  },
  {
    key: 'category',
    label: 'Catégorie',
    align: 'left',
  },
  {
    key: 'subCategory',
    label: 'Sous-catégorie',
    align: 'left',
  },
  {
    key: 'price',
    label: 'Prix/u',
    align: 'left',
  },
  {
    key: 'quantity',
    label: 'Quantité',
    align: 'center',
  },
  {
    key: 'total',
    label: 'Total',
    align: 'right',
  },
];

function ProductsTable({ products, handleIncrease, handleDescrease, handleChangeQuantity }) {
  const classes = useStyles();

  const totalQuantity = products.reduce((acc, curr) => acc + curr.quantity, 0);
  const totalPrice = products.reduce((acc, curr) => acc + curr.price * curr.quantity, 0);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {TABLE_HEADER_CELLS.map((cell) => (
              <TableCell key={cell.key} align={cell.align} variant="head">
                <b>{cell.label}</b>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow key={product.id}>
              <TableCell>{product.name}</TableCell>
              <TableCell>{product.categoryName}</TableCell>
              <TableCell>{product.subCategoryName}</TableCell>
              <TableCell>{formatPrice(product.price)}</TableCell>
              <TableCell align="center" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={classes.quantityController}>
                  <Button onClick={() => handleDescrease(product.id)}>-</Button>
                  <TextField
                    variant="outlined"
                    value={product.quantity}
                    type="number"
                    inputProps={{ min: 1 }}
                    onChange={(event) => handleChangeQuantity(product.id, event.target.value)}
                  />
                  <Button onClick={() => handleIncrease(product.id)}>+</Button>
                </div>
              </TableCell>
              <TableCell align="right">{formatPrice(product.price * product.quantity)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={3} />
            <TableCell>
              <b>Total</b>
            </TableCell>
            <TableCell align="center">
              <b>{totalQuantity}</b>
            </TableCell>
            <TableCell align="right">
              <b>{formatPrice(totalPrice)}</b>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}

export default function ProductList({ products, mb, handleIncrease, handleDescrease, handleChangeQuantity }) {
  const classes = useStyles();

  const hasProducts = products.length > 0;

  return (
    <Box mb={mb}>
      <Paper className={classes.root}>
        <h3>Produits de la commande</h3>
        {!hasProducts && <p>Aucun produit</p>}
        {hasProducts && (
          <ProductsTable
            products={products}
            handleIncrease={handleIncrease}
            handleDescrease={handleDescrease}
            handleChangeQuantity={handleChangeQuantity}
          />
        )}
      </Paper>
    </Box>
  );
}
