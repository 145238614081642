import { Divider, Paper } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2),
    overflow: 'auto',
    display: 'flex',
    gap: '3rem',
    marginBottom: '0.5rem',

    '& > div': {
      display: 'flex',
      alignItems: 'center',
      gap: '1rem',
      '& > p': {
        fontWeight: 'bold',
        margin: '0',

        '& > span': {
          display: 'block',
          fontWeight: 'normal',
          textTransform: 'capitalize',
        },
      },
    },
  },
}));
export default function OrderHeaderSummary({ headers }) {
  const classes = useStyles();

  return (
    <Paper className={classes.header}>
      {headers.map((header, index) => (
        <React.Fragment key={header.key}>
          {index > 0 && <Divider style={{ height: '50px' }} orientation="vertical" light />}
          <div>
            {header.Icon}
            <p>
              {header.label} : <span>{header.value}</span>
            </p>
          </div>
        </React.Fragment>
      ))}
    </Paper>
  );
}
