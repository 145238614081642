import React from 'react';

import AppRoute from '../../shared/routes/AppRoute';
import Back from '../../layouts/Back';
import { ADMIN_STATISTICS_APP_PATHS } from '../../shared/statistics/constants/path.constants';
import AdminStatisticsCommissionPage from '../../views/admin/statistics/commission/Page';

export default function AdminStatisticsRoutes() {
  return (
    <>
      <AppRoute
        exact
        path={ADMIN_STATISTICS_APP_PATHS.COMMISIONS}
        layout={Back}
        component={AdminStatisticsCommissionPage}
      />
    </>
  );
}
