import React, { useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import generateFakeUuid from '../../../shared/utils/generateFakeUuid';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflow: 'auto',

    '& > h3': {
      margin: '0 0 1rem 0',
    },

    '& > div': {
      display: 'flex',
      gap: '1rem',

      '& > section:first-of-type': {
        flexGrow: 2,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: '1rem',
      },

      '& > section:last-of-type': {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        gap: '1rem',
        justifyContent: 'end',

        '& > .MuiFormControlLabel-root': {
          margin: '0',

          '& > .MuiCheckbox-root': {
            padding: '0',
          },
        },
      },
    },
  },
}));

export default function AddVirtualProduct({ handleAddProduct, mb }) {
  const classes = useStyles();

  const [productName, setProductName] = useState('Custom Product');
  const [productQuantity, setProductQuantity] = useState(1);
  const [productPrice, setProductPrice] = useState(10);
  const [productTvaRate, setProductTvaRate] = useState('5,5');
  const [productSize, setProductSize] = useState('M');

  const [isNoLabel, setIsNoLabel] = useState(false);

  const handleProductQuantity = (value) => setProductQuantity(Number(value));

  const virtualProductFields = [
    { label: 'Nom', name: 'name', type: 'Text', span: 2, defaultValue: productName, onChangeFn: setProductName },
    {
      label: 'Quantité',
      name: 'quantity',
      type: 'Number',
      min: 1,
      span: 1,
      defaultValue: productQuantity,
      onChangeFn: handleProductQuantity,
    },
    {
      label: 'Prix',
      name: 'price',
      type: 'Number',
      span: 1,
      endAdornment: '€',
      defaultValue: productPrice,
      onChangeFn: setProductPrice,
    },
    {
      label: 'Taux TVA',
      name: 'tvaRate',
      type: 'Text',
      span: 1,
      endAdornment: '%',
      defaultValue: productTvaRate,
      onChangeFn: setProductTvaRate,
    },
  ];

  const handleSubmit = () => {
    handleAddProduct({
      id: generateFakeUuid(),
      type: 'virtual',
      categoryName: 'Hors catalogue',
      subCategoryName: '',
      ref: 'virtual_product',
      name: productName,
      quantity: productQuantity,
      price: Number(productPrice),
      tvaRate: productTvaRate,
      size: productSize,
      noLabel: isNoLabel,
    });
  };

  return (
    <Box mb={mb}>
      <Paper className={classes.root}>
        <h3>Ajouter un produit hors catalogue</h3>
        <div>
          <section>
            {virtualProductFields.map((field) => (
              <TextField
                key={`${field.name}-${field.label}`}
                style={{ gridColumn: `span ${field.span}` }}
                variant="outlined"
                type={field.type}
                label={field.label}
                name={field.name}
                InputProps={{
                  ...(field.min ? { inputProps: { min: field.min } } : {}),
                  ...(field.endAdornment
                    ? { endAdornment: <InputAdornment position="end">{field.endAdornment}</InputAdornment> }
                    : {}),
                }}
                endAdor
                defaultValue={field.defaultValue}
                onChange={(event) => field.onChangeFn(event.target.value)}
              />
            ))}
            <FormControl variant="outlined">
              <InputLabel>Taille</InputLabel>
              <Select
                autoWidth
                label="Taille"
                name="size"
                onChange={(event) => setProductSize(event.target.value)}
                value={productSize}
              >
                {['S', 'M', 'L', 'XL', 'SOLO'].map((size) => (
                  <MenuItem key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </section>
          <section>
            <FormControlLabel
              control={<Checkbox checked={isNoLabel} onChange={() => setIsNoLabel((prev) => !prev)} name="no_label" />}
              label="Pas d'étiquette"
            />
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Ajouter
            </Button>
          </section>
        </div>
      </Paper>
    </Box>
  );
}
