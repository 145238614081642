import React from 'react';

export default function SecondSection({ params }) {
  const { fullname, address, city, zipCode, phone, complement, comment } = params;
  return (
    <>
      <p>
        <b>Eco-acteur :</b> {fullname}
      </p>
      <p>
        <b>Adresse : </b>
        {address} - {city} {zipCode}
      </p>
      {phone && (
        <p>
          <b>Téléphone :</b> {phone}
        </p>
      )}
      {complement && (
        <p>
          <b>Complément : </b>
          <br />
          {complement}
        </p>
      )}
      {comment && (
        <p>
          <b>Commentaire : </b>
          <br />
          {comment}
        </p>
      )}
    </>
  );
}
