import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },
});

export default function UpdateOrderAdressForm({ isEditable = true, selectedAddress, setSelectedAddress }) {
  const classes = useStyles();
  const { address = '', zipCode = '', city = '', complement = '', comment = '' } = selectedAddress ?? {};

  const handleAddressChange = (field, value) => {
    if (!setSelectedAddress) return;
    const currentAddress = selectedAddress ?? {
      address: '',
      zipCode: '',
      city: '',
      complement: '',
      comment: '',
    };

    setSelectedAddress({ ...currentAddress, [field]: value });
  };

  return (
    <form className={classes.root} noValidate autoComplete="off">
      <TextField
        disabled={!isEditable}
        label="Adresse de livraison"
        variant="outlined"
        value={address}
        onChange={(event) => handleAddressChange('address', event.target.value)}
      />
      <TextField
        disabled={!isEditable}
        label="Code postal"
        variant="outlined"
        value={zipCode}
        onChange={(event) => handleAddressChange('zipCode', event.target.value)}
      />
      <TextField
        disabled={!isEditable}
        label="Ville"
        variant="outlined"
        value={city}
        onChange={(event) => handleAddressChange('city', event.target.value)}
      />
      <TextField
        label="Complément d'adresse"
        variant="outlined"
        multiline
        maxRows={4}
        value={complement}
        onChange={(event) => handleAddressChange('complement', event.target.value)}
      />
      <TextField
        label="Commentaire"
        variant="outlined"
        multiline
        maxRows={4}
        value={comment}
        onChange={(event) => handleAddressChange('comment', event.target.value)}
      />
    </form>
  );
}
