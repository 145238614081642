import moment from 'moment/moment';
import React from 'react';
export default function FirstSection({ params }) {
  const { city, zipCode, deliveryDate, deliverySchedule } = params;
  return (
    <>
      <p>
        {city} - {zipCode}
      </p>
      <p style={{ textTransform: 'capitalize' }}>{moment(deliveryDate).format('dddd Do MMMM YYYY')}</p>
      {deliverySchedule && <p>{deliverySchedule}</p>}
    </>
  );
}
