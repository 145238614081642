import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import HomeDeliverySectionHeader from './header/HomeDeliverySectionHeader';
import DeliveryNoteList from '../DeliveryNoteList';

const useDeliverySectionStyle = makeStyles({
  root: {
    marginBottom: '1.5rem',
    pageBreakAfter: 'always',
  },
});
export default function HomeDeliveryNoteSection({ delivery }) {
  const classes = useDeliverySectionStyle();

  return (
    <section className={classes.root}>
      <HomeDeliverySectionHeader order={delivery} />
      <DeliveryNoteList orders={[delivery]} />
    </section>
  );
}
