import React from 'react';

import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

function getSizeProps(size) {
  switch (size) {
    case 'S':
      return { color: '#2ECC71', label: 'S' };
    case 'M':
      return { color: '#F1C40F', label: 'M' };
    case 'L':
      return { color: '#E67E22', label: 'L' };
    case 'XL':
      return { color: '#E74C3C', label: 'XL' };
    case 'SOLO':
      return { color: '#3498DB', label: 'SOLO' };
    default:
      return { color: '#BDC3C7', label: 'Inconnu' };
  }
}

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    backgroundColor: props.color,
    borderRadius: 5,
  }),
}));

export function ProductSizeChip({ size, chipSize = 'small' }) {
  const props = getSizeProps(size);

  const classes = useStyles(props);

  return <Chip className={classes.root} label={props.label} size={chipSize} />;
}
