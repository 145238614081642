import React, { useMemo } from 'react';

import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Area, AreaChart } from 'recharts';
import { useGetSalesComparison } from '../../../shared/dashboard/hooks/dashboard.hooks';
import Skeleton from '@material-ui/lab/Skeleton';
import { formatPrice } from '../../../shared/utils/format-price';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    padding: theme.spacing(2),
  },

  header: {
    display: 'flex',

    '& > h4': {
      margin: 0,
      flexGrow: 1,
    },
  },

  legend: {
    display: 'flex',
    gap: theme.spacing(3),
    flexGrow: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',

    '& > p': {
      margin: 0,
      fontSize: '12px',
    },
  },

  chart: {
    flexGrow: 3,
  },

  toolTip: {
    padding: theme.spacing(1),
    background: 'rgba(255, 255, 255, 0.2)',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    borderRadius: '5px',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
    backdropFilter: 'blur(5px)',
    WebkitBackdropFilter: 'blur(5px)',

    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),

    '& > p': {
      textTransform: 'capitalize',
      margin: 0,

      '& > span': {
        fontWeight: 'bold',
      },
    },
  },
}));

function formatNumber(num) {
  if (num >= 1000) return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'k';
  return num.toString();
}

function CustomTooltip({ active, payload }) {
  const classes = useStyles();

  if (active && payload && payload.length) {
    const { currentWeekFormattedDate, lastWeekFormattedDate } = payload[0].payload;
    return (
      <section className={classes.toolTip}>
        <p>
          {currentWeekFormattedDate}: <span style={{ color: '#ff7300' }}>{formatPrice(payload[1].value)}</span>
        </p>
        <p>
          {lastWeekFormattedDate}: <span style={{ color: '#7c7c7c' }}>{formatPrice(payload[0].value)}</span>
        </p>
      </section>
    );
  }

  return null;
}

export default function SalesPerformanceComparison() {
  const classes = useStyles();

  const { loading, currentWeek, lastWeek } = useGetSalesComparison();

  const formattedData = useMemo(() => {
    if (!currentWeek || !lastWeek) return [];
    return [
      {
        name: 'Lundi',
        currentWeekFormattedDate: currentWeek?.['lundi']?.formattedDate ?? '',
        currentWeekSales: currentWeek?.['lundi']?.totalPaid ?? 0,
        lastWeekFormattedDate: lastWeek?.['lundi']?.formattedDate ?? '',
        lastWeekSales: lastWeek?.['lundi']?.totalPaid ?? 0,
      },
      {
        name: 'Mardi',
        currentWeekFormattedDate: currentWeek?.['mardi']?.formattedDate ?? '',
        currentWeekSales: currentWeek?.['mardi']?.totalPaid ?? 0,
        lastWeekFormattedDate: lastWeek?.['mardi']?.formattedDate ?? '',
        lastWeekSales: lastWeek?.['mardi']?.totalPaid ?? 0,
      },
      {
        name: 'Mercredi',
        currentWeekFormattedDate: currentWeek?.['mercredi']?.formattedDate ?? '',
        currentWeekSales: currentWeek?.['mercredi']?.totalPaid ?? 0,
        lastWeekFormattedDate: lastWeek?.['mercredi']?.formattedDate ?? '',
        lastWeekSales: lastWeek?.['mercredi']?.totalPaid ?? 0,
      },
      {
        name: 'Jeudi',
        currentWeekFormattedDate: currentWeek?.['jeudi']?.formattedDate ?? '',
        currentWeekSales: currentWeek?.['jeudi']?.totalPaid ?? 0,
        lastWeekFormattedDate: lastWeek?.['jeudi']?.formattedDate ?? '',
        lastWeekSales: lastWeek?.['jeudi']?.totalPaid ?? 0,
      },
      {
        name: 'Vendredi',
        currentWeekFormattedDate: currentWeek?.['vendredi']?.formattedDate ?? '',
        currentWeekSales: currentWeek?.['vendredi']?.totalPaid ?? 0,
        lastWeekFormattedDate: lastWeek?.['vendredi']?.formattedDate ?? '',
        lastWeekSales: lastWeek?.['vendredi']?.totalPaid ?? 0,
      },
      {
        name: 'Samedi',
        currentWeekFormattedDate: currentWeek?.['samedi']?.formattedDate ?? '',
        currentWeekSales: currentWeek?.['samedi']?.totalPaid ?? 0,
        lastWeekFormattedDate: lastWeek?.['samedi']?.formattedDate ?? '',
        lastWeekSales: lastWeek?.['samedi']?.totalPaid ?? 0,
      },
      {
        name: 'Dimanche',
        currentWeekFormattedDate: currentWeek?.['dimanche']?.formattedDate ?? '',
        currentWeekSales: currentWeek?.['dimanche']?.totalPaid ?? 0,
        lastWeekFormattedDate: lastWeek?.['dimanche']?.formattedDate ?? '',
        lastWeekSales: lastWeek?.['dimanche']?.totalPaid ?? 0,
      },
    ];
  }, [currentWeek, lastWeek]);

  return (
    <>
      {loading && <Skeleton variant="rect" width={500} height={150} />}
      {!loading && formattedData.length && (
        <Paper className={classes.root}>
          <section className={classes.header}>
            <h4>Performance de la semaine</h4>
            <div className={classes.legend}>
              <p style={{ color: '#ff7300' }}>Semaine actuelle</p>
              <p style={{ color: '#7c7c7c' }}>Semaine dernière</p>
            </div>
          </section>
          <section className={classes.chart}>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={formattedData}
                margin={{
                  top: 5,
                  right: 0,
                  left: -25,
                  bottom: -10,
                }}
              >
                <defs>
                  <linearGradient id="grayGradiant" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="10%" stopColor="#7c7c7c" stopOpacity={0.5} />
                    <stop offset="100%" stopColor="#7c7c7c" stopOpacity={0.05} />
                  </linearGradient>
                  <defs>
                    <linearGradient id="orangeGradiant" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="10%" stopColor="#ff7300" stopOpacity={0.5} />
                      <stop offset="100%" stopColor="#ff7300" stopOpacity={0.05} />
                    </linearGradient>
                  </defs>
                </defs>
                <CartesianGrid vertical={false} stroke="#e3e3e3" strokeDasharray="4" />
                <XAxis tickLine={false} axisLine={false} dataKey="name" />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tickFormatter={(tick) => (tick === 0 ? '' : formatNumber(tick))}
                />
                <Tooltip content={<CustomTooltip />} cursor={{ strokeWidth: 1, strokeDasharray: 4 }} />
                <Area type="monotone" dataKey="lastWeekSales" stroke="#7c7c7c" fill="url(#grayGradiant)" />
                <Area type="monotone" dataKey="currentWeekSales" stroke="#ff7300" fill="url(#orangeGradiant)" />
              </AreaChart>
            </ResponsiveContainer>
          </section>
        </Paper>
      )}
    </>
  );
}
