import moment from 'moment/moment';
import React from 'react';
import plurialFormatter from '../../../../../../utils/plurialFormatter';

export default function FirstSection({ params }) {
  const { ordersLen, city, zipCode, deliveryDate, deliverySchedule } = params;
  return (
    <>
      <p style={{ marginBottom: '0.5rem' }}>
        <b>
          {ordersLen} {plurialFormatter(ordersLen, 'commande', 'commandes')}
        </b>
      </p>
      <p>
        {city} - {zipCode}
      </p>
      <p style={{ textTransform: 'capitalize' }}>{moment(deliveryDate).format('dddd Do MMMM YYYY')}</p>
      {deliverySchedule && <p>{deliverySchedule}</p>}
    </>
  );
}
