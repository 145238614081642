import React, { useState, useEffect, Fragment } from 'react';

import Cookies from 'js-cookie';
import { useParams } from 'react-router';

import { totalPrice } from '../../../utils/totalOrders';

import {
  Grid,
  Paper,
  Chip,
  Button,
  Switch,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Table,
  Badge,
  Link,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

import { useSnackbar } from 'notistack';

import moment from 'moment';
import 'moment/locale/fr';
import axios from 'axios';
import currencyFormater from '../../../utils/currencyFormater';
import { ADMIN_ORDER_APP_PATHS } from '../../../shared/order/constants/path.constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  flex: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '&>*:nth-child(1)': {
      margin: '0 1rem 0 0',
    },
  },
  flexTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexBot: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  editing: {
    display: 'flex',
    flexDirection: 'column',
    '&>*': {
      marginBottom: '1rem',
    },
  },
  marginB: {
    margin: '0 0 1rem 0',
  },
  marginR: {
    margin: '0 1rem 0 0',
  },
  margin: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '316px',
  },
  button: {
    margin: '0 5px 0 5px',
    height: 'fit-content',
  },
  padding: {
    padding: '0.5rem',
  },
}));

export default function DiscountDetails() {
  moment.locale('fr');
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const jwt = Cookies.get('jwt');
  const [state, setState] = useState({
    editing: false,
    loading: true,
    discount: {
      name: '',
      value: '',
      reduction: '',
      isActive: '',
      count: 0,
      createdAt: '',
      expireAt: '',
      limited: '',
      minAmount: 0,
      segment: {
        name: '',
      },
    },
    discountUpdate: {
      name: null,
      value: null,
      reduction: null,
      isActive: null,
      segments: null,
      count: null,
      createdAt: null,
      expireAt: null,
      limited: null,
      minAmount: null,
    },
    segments: [],
    orders: [],
  });
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      const currDiscount = await axios.get(`${process.env.REACT_APP_API}/discounts/${id}`);
      const orders = await axios.get(`${process.env.REACT_APP_API}/orders/discount/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      const segments = await axios
        .get(`${process.env.REACT_APP_API}/segments/?isActive=true`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        })
        .catch((err) => {
          console.log(err.response);
        });

      const { discount } = currDiscount.data.data;
      const { name, value, reduction, isActive, segment, limited, minAmount, count, createdAt, expireAt } = discount;
      setState((prev) => ({
        ...prev,
        discount,
        discountUpdate: {
          name,
          value,
          reduction,
          isActive,
          segment,
          limited,
          minAmount,
          count,
          createdAt,
          expireAt: moment(expireAt).format('YYYY-MM-DD'),
        },
        segments: segments.data.data.segments,
        loading: false,
        orders: orders.data.orders,
      }));
    };
    fetchData();
  }, [id, jwt]);

  const columns = [
    { id: 'id', label: 'Id commande', minWidth: 100, align: 'left' },
    { id: 'owner', label: 'Propriétaire', minWidth: 100, align: 'left' },
    { id: 'date', label: "Date d'utilisation", minWidth: 50, align: 'center' },
    { id: 'status', label: 'Status', minWidth: 50, align: 'center' },
    { id: 'advStatus', label: 'ADV Status', minWidth: 50, align: 'center' },
    { id: 'price', label: 'Montant', minWidth: 50, align: 'center' },
  ];

  const handleEditing = (bool) => {
    setState((prev) => ({
      ...prev,
      editing: bool,
    }));
  };

  const handleChange = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      discountUpdate: {
        ...prev.discountUpdate,
        [event.target.name]: event.target.value,
      },
    }));
  };

  const handleChangeNumb = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      discountUpdate: {
        ...prev.discountUpdate,
        [event.target.name]: event.target.value * 1,
      },
    }));
  };

  const handleChangeSwitch = (event) => {
    event.persist();
    setState((prev) => ({
      ...prev,
      discountUpdate: {
        ...prev.discountUpdate,
        [event.target.name]: event.target.checked,
      },
    }));
  };

  const handleUpdate = async () => {
    const token = Cookies.get('jwt');

    await axios
      .patch(`${process.env.REACT_APP_API}/discounts/${id}`, state.discountUpdate, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        enqueueSnackbar(`Une problème est survenu`, {
          variant: 'error',
        });
      });
  };

  const handleDelete = async () => {
    const token = Cookies.get('jwt');

    await axios
      .delete(`${process.env.REACT_APP_API}/discounts/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location = '/admin/discounts/list/all';
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const calcTotalPaid = () => {
    let total = 0;
    const ordersPaid = state.orders.filter((order) => order.status === 'paid' && order.advStatus === 'validate');

    ordersPaid.forEach((order) => {
      if (order.discount.reduction === 'flat') {
        const totalItemPrice = order.items.reduce((acc, curr) => acc + curr.quantity * curr.price, 0);
        total += totalItemPrice + order.deliveryCost - order.discount.value;
      } else {
        const totalItemPrice = order.items.reduce((acc, curr) => acc + curr.quantity * curr.price, 0);
        const reduction = order.discount.value * 0.01 * totalItemPrice;
        total += totalItemPrice + order.deliveryCost - reduction;
      }
    });

    return currencyFormater(total);
  };

  return (
    <>
      {state.loading ? null : (
        <div className={classes.flexTop}>
          <h1>Bon d'achat : {state.discount.name}</h1>
          {state.editing ? (
            <div>
              <Button className={classes.marginR} variant="contained" color="secondary" onClick={(e) => handleDelete()}>
                Supprimer
              </Button>
              <Button variant="contained" color="primary" onClick={(e) => handleEditing(false)}>
                Annuler
              </Button>
            </div>
          ) : (
            <div className={classes.margin}>
              <div>
                <Button variant="contained" color="primary" onClick={(e) => handleEditing(true)}>
                  Modifier
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <Paper className={classes.paper}>
                <h3 style={{ margin: '0' }}>
                  Nombre d'utilisation : <span style={{ fontWeight: '200' }}>{state.orders.length}</span>
                </h3>
              </Paper>
            </Grid>

            <Grid item xs={4}>
              <Paper className={classes.paper}>
                <h3 style={{ margin: '0' }}>
                  Chiffre d'affaire payé : <span style={{ fontWeight: '200' }}>{calcTotalPaid()}</span>
                </h3>{' '}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Paper className={classes.paper}>
            <h3>Informations :</h3>
            {state.editing ? (
              <section>
                <div className={classes.editing}>
                  <div className={classes.flex}>
                    <FormControlLabel
                      control={
                        <Switch checked={state.discountUpdate.isActive} name="isActive" onChange={handleChangeSwitch} />
                      }
                      label={state.discountUpdate.isActive ? 'Actif' : 'Inactif'}
                    />
                    <FormControlLabel
                      control={
                        <Switch checked={state.discountUpdate.limited} name="limited" onChange={handleChangeSwitch} />
                      }
                      label={state.discountUpdate.limited ? 'Limité' : 'Illimité'}
                    />
                  </div>
                  <TextField
                    className={classes.marginB}
                    label="Nom"
                    variant="outlined"
                    type="text"
                    name="name"
                    defaultValue={state.discountUpdate.name}
                    onChange={handleChange}
                    fullWidth
                  />
                  <div className={classes.flex}>
                    <TextField
                      className={classes.marginR}
                      label="Utilisations disponibles"
                      variant="outlined"
                      type="number"
                      name="count"
                      defaultValue={state.discountUpdate.count}
                      onChange={handleChangeNumb}
                    />
                    <FormControl variant="outlined">
                      <InputLabel>Segment</InputLabel>
                      <Select
                        native
                        label="Segment"
                        defaultValue={state.discountUpdate.segment}
                        onChange={handleChange}
                        name="segment"
                      >
                        <option value="" />
                        {state.segments.map((segment, idx) => (
                          <option key={idx} value={segment._id}>
                            {segment.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.flex}>
                    <TextField
                      label="Date limite d'utilisation"
                      type="date"
                      variant="outlined"
                      name="expireAt"
                      defaultValue={state.discountUpdate.expireAt}
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      label="Montant minimum"
                      type="Number"
                      variant="outlined"
                      name="minAmount"
                      defaultValue={state.discountUpdate.minAmount}
                      onChange={handleChangeNumb}
                    />
                  </div>
                  <div className={classes.flex}>
                    <TextField
                      className={classes.marginB}
                      label="Réduction"
                      variant="outlined"
                      type="number"
                      name="value"
                      defaultValue={state.discountUpdate.value}
                      onChange={handleChangeNumb}
                    />
                    <FormControl variant="outlined">
                      <InputLabel>Type</InputLabel>
                      <Select
                        native
                        defaultValue={state.discountUpdate.reduction}
                        label="Type"
                        name="reduction"
                        onChange={handleChange}
                      >
                        <option value="percent">%</option>
                        <option value="flat">€</option>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={classes.flexBot}>
                  <Button variant="contained" color="primary" onClick={handleUpdate}>
                    Valider les modifications
                  </Button>
                </div>
              </section>
            ) : (
              <section>
                <div className={classes.flex}>
                  <Chip
                    size="small"
                    label={state.discount.isActive ? 'Actif' : 'Inactif'}
                    color={state.discount.isActive ? 'primary' : 'secondary'}
                  />
                  <Chip
                    size="small"
                    label={state.discount.limited ? 'Limité' : 'Illimité'}
                    color={state.discount.limited ? 'primary' : 'secondary'}
                  />
                </div>
                <p>
                  <b>ID :</b> {state.discount._id}
                </p>
                <p>
                  <b>Nom :</b> {state.discount.name}
                </p>
                <p>
                  <b>Date de création :</b> {moment(state.discount.createdAt).format('dddd Do MMMM YYYY')}
                </p>
                {state.discount.segment ? (
                  <p>
                    <b>Segment :</b>{' '}
                    <Link href={`/admin/segments/${state.discount.segment._id}`}>{state.discount.segment.name}</Link>
                  </p>
                ) : null}
                <p>
                  <b>Utilisations disponibles :</b> {state.discount.count}
                </p>
                <p>
                  <b>Date limite d'utilisation :</b> {moment(state.discount.expireAt).format('dddd Do MMMM YYYY')}
                </p>
                <p>
                  <b>Réduction :</b> {state.discount.value} {state.discount.reduction === 'percent' ? '%' : '€'}
                </p>
                <p>
                  <b>Montant minimum :</b> {state.discount.minAmount} €
                </p>
              </section>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Paper className={classes.paper}>
            <h3>Historique d'utilisation</h3>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, idx) => (
                      <TableCell key={idx} align={column.align} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.orders.map((order, idx) => (
                    <TableRow key={idx}>
                      <TableCell align="left">
                        <Link href={ADMIN_ORDER_APP_PATHS.ORDER_SUMMARY.replace(':orderId', order._id)}>
                          {order._id.substr(order.owner.id.length - 5)}
                        </Link>
                      </TableCell>
                      <TableCell align="left">
                        <Link href={`/admin/users/${order.owner.id}`}>{order.owner.fullname}</Link>
                      </TableCell>
                      <TableCell align="center">{moment(order.createdAt).format('LL')}</TableCell>
                      <TableCell align="center">
                        <Badge
                          color={order.status === 'paid' ? 'primary' : 'secondary'}
                          badgeContent={order.status === 'paid' ? 'Payé' : 'Attente'}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <Badge
                          color={
                            order.advStatus === 'validate'
                              ? 'primary'
                              : order.advStatus === 'archived'
                              ? 'primary'
                              : 'secondary'
                          }
                          badgeContent={
                            order.advStatus === 'validate'
                              ? 'Validé'
                              : order.advStatus === 'canceled'
                              ? 'Annulé'
                              : order.advStatus === 'archived'
                              ? 'Archivé'
                              : 'Attente'
                          }
                        />
                      </TableCell>
                      <TableCell>{totalPrice(order)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
