import React, { useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button, Divider, FormControl, InputLabel, Paper, Select, TextField } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import DownloadXlsxButton from '../../../../components/shared/Button/DownloadXlsxButton';
import HomeDeliveryNoteTable from '../../../../components/Admin/Delivery/Notes/home/HomeDeliveryNoteTable';
import EcoDeliveryNoteTable from '../../../../components/Admin/Delivery/Notes/eco/EcoDeliveryNoteTable';
import RelayDeliveryNoteTable from '../../../../components/Admin/Delivery/Notes/relay/RelayDeliveryNoteTable';
import PrintList from '../../../../components/Admin/Delivery/Notes/PrintList';
import { useGetDeliveriesByDate } from '../../../../shared/order/hooks/order.hooks';
import { ADMIN_MANY_ORDERS_API_PATHS } from '../../../../shared/order/constants/path.constants';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',

    '& h3': {
      margin: '0 0 0.5rem 0',
    },
  },
  firstLine: {
    '& > div': {
      minWidth: '150px',
      marginRight: '1rem',
    },
  },
  deliveryType: {
    padding: '1rem',
    marginBottom: '1rem',
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function AdminOrdersDeliveryNotesPage() {
  moment.locale('fr');
  const classes = useStyles();

  const [searchDate, setSearchDate] = useState(moment().format('YYYY-MM-DD'));
  const [segment, setSegment] = useState(undefined);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const { loading, orders } = useGetDeliveriesByDate({ deliveryDate: searchDate, segment });
  const { home: homeOrders, relay: relayOrders, eco: ecoOrders } = orders;

  const handleDate = (date) => setSearchDate(date);

  const handleSegmentChange = (event) => {
    const { value } = event.target;
    if (value === 'all') return setSegment(undefined);
    setSegment(value);
  };

  const downloadXlsxUrl = useMemo(() => {
    let queryUrl = `${ADMIN_MANY_ORDERS_API_PATHS.DOWNLOAD_ORDERS_LIST_XLSX.replace(':deliveryDate', searchDate)}`;
    if (segment) queryUrl += `?segment=${segment}`;

    return queryUrl;
  }, [searchDate, segment]);

  return (
    <>
      {!loading && (
        <div style={{ display: 'none' }}>
          <PrintList ref={componentRef} orders={orders} />
        </div>
      )}
      <div className={classes.flex}>
        <h2>Toutes les bons de livraisons</h2>
        <div>
          <DownloadXlsxButton
            label="Télécharger le xlsx"
            url={downloadXlsxUrl}
            filename={`orders_recap_${searchDate}.xlsx`}
          />
          <Button variant="contained" color="primary" style={{ marginLeft: '1rem' }} onClick={handlePrint}>
            Imprimer les bons
          </Button>
        </div>
      </div>
      <Paper className={classes.paper}>
        <h4
          style={{
            margin: '0 0 1rem 0',
          }}
        >
          Filtrer les bons
        </h4>
        <div className={classes.firstLine}>
          <FormControl variant="outlined">
            <InputLabel>Segment</InputLabel>
            <Select native defaultValue="all" label="Segment" onChange={handleSegmentChange}>
              <option value="all">Tous</option>
              <option value="user">Utilisateur simple</option>
              <option value="subscriberBtoC">Abonnée B2C</option>
              <option value="subscriberBtoB">Abonnée B2B</option>
              <option value="eco">Eco-acteur</option>
              <option value="conciergerie">Conciergerie</option>
              <option value="admin">Admin</option>
              <option value="superAdmin">Super admin</option>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            label="Date de livraison"
            type="date"
            defaultValue={searchDate}
            onChange={(e) => handleDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </Paper>
      {loading ? (
        <Skeleton variant="rect" width="100%" height={250} />
      ) : (
        <Paper className={classes.deliveryType}>
          <HomeDeliveryNoteTable deliveries={homeOrders} />
          <Divider />
          <RelayDeliveryNoteTable deliveries={relayOrders} />
          <Divider />
          <EcoDeliveryNoteTable deliveries={ecoOrders} />
        </Paper>
      )}
    </>
  );
}
