import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { axiosGetRequest } from '../../../utils/axiosRequests';
import { useSnackbar } from 'notistack';
import { ADMIN_DASHBOARD_API_PATHS } from '../constants/path.constants';

export const useGetSalesComparison = () => {
  const { enqueueSnackbar } = useSnackbar();
  const token = Cookies.get('jwt');

  const [result, setResult] = useState({
    loading: true,
    lastWeek: undefined,
    currentWeek: undefined,
  });

  useEffect(() => {
    const fetchData = () => axiosGetRequest(ADMIN_DASHBOARD_API_PATHS.GET_SALES_COMPARISON, token);

    fetchData()
      .then((response) => {
        const { data } = response.data;

        setResult({
          loading: false,
          lastWeek: data.lastWeek,
          currentWeek: data.currentWeek,
        });
      })
      .catch((error) => {
        const message =
          error.response?.data?.message ?? 'Une erreur est survenue lors de la récupération des statistiques';

        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
  }, [token]);

  return result;
};
