import React, { useMemo } from 'react';

import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatPaymentStatus } from '../../../../shared/order/utils/format-status';
const getChipStyle = (payment) => {
  switch (payment) {
    case 'abandoned':
      return { backgroundColor: '#f0f0f0', color: '#000000' }; // Light Grey
    case 'canceled':
      return { backgroundColor: '#f44336', color: '#ffffff' }; // Red
    case 'paid':
      return { backgroundColor: '#4caf50', color: '#ffffff' }; // Green
    case 'refuse':
      return { backgroundColor: '#ff9800', color: '#ffffff' }; // Orange
    case 'waiting':
      return { backgroundColor: '#2196f3', color: '#ffffff' }; // Blue
    case 'refund':
      return { backgroundColor: '#8e44ad', color: '#ffffff' }; // Dark Purple
    case 'partial':
      return { backgroundColor: '#f1c40f', color: '#000000' }; // Gold
    default:
      return { backgroundColor: '#f0f0f0', color: '#000000' }; // Light Grey
  }
};

const useStyles = makeStyles(() => ({
  root: (props) => ({
    borderRadius: 4,
    textTransform: 'capitalize',
    backgroundColor: props.backgroundColor,
    color: props.color,
  }),
}));

export default function PaymentStatusChip({ status, size = 'small' }) {
  const { label, backgroundColor, color } = useMemo(() => {
    return {
      label: formatPaymentStatus(status),
      ...getChipStyle(status),
    };
  }, [status]);

  const classes = useStyles({ backgroundColor, color });

  return <Chip className={classes.root} label={label} size={size} />;
}
