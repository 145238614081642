import moment from 'moment/moment';
import React from 'react';
export default function FirstSection({ params }) {
  const { ordersLen, deliveryDate, depositSchedule, deliverySchedule, city, zipCode } = params;
  return (
    <>
      <p style={{ marginBottom: '0.5rem' }}>
        <b>{ordersLen} commandes</b>
      </p>
      <p>
        {city} - {zipCode}
      </p>
      <p style={{ textTransform: 'capitalize' }}>{moment(deliveryDate).format('dddd Do MMMM YYYY')}</p>
      {depositSchedule && <p>Livraison : {deliverySchedule}</p>}
      {deliverySchedule && <p>Retrait client : {deliverySchedule}</p>}
    </>
  );
}
