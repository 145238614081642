import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import { axiosGetRequest } from '../../../utils/axiosRequests';
import { ADMIN_STATISTICS_API_PATHS } from '../constants/path.constants';
import moment from 'moment';

export default function useGetCommissionsStats({
  dateType = 'delivery',
  month = moment().format('YYYY-MM'),
  advStatus = 'archived',
  segmentId,
  relayId,
}) {
  const token = Cookies.get('jwt');
  const { enqueueSnackbar } = useSnackbar();

  const initialState = { loading: true, commissions: null };

  const [result, setResult] = useState(initialState);

  useEffect(() => {
    setResult(initialState);

    if (!segmentId) return;

    let url = `${ADMIN_STATISTICS_API_PATHS.COMMISSIONS}?dateType=${dateType}&month=${month}&advStatus=${advStatus}&segmentId=${segmentId}`;
    if (relayId) url += `&relayId=${relayId}`;

    const fetchData = () => axiosGetRequest(url, token);

    fetchData()
      .then((response) => {
        const { commissions } = response.data;

        setResult({
          loading: false,
          commissions,
        });
      })
      .catch((error) => {
        const message =
          error.response?.data?.message ?? 'Une erreur est survenue lors de la récupération des commissions';

        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
  }, [token, dateType, month, advStatus, relayId, segmentId]);

  return result;
}
