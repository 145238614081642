import React from 'react';

import { FormControl, InputLabel, Select } from '@material-ui/core';

export default function AdvStatusSelect({ selectedValue, setSelectedValue }) {
  const handleChange = (event) => setSelectedValue(event.target.value);

  return (
    <FormControl variant="outlined">
      <InputLabel>Statut ADV</InputLabel>
      <Select native value={selectedValue} label="Statut ADV" onChange={handleChange}>
        <option value="canceled">Annulée</option>
        <option value="archived">Archivée</option>
        <option value="input">En attente</option>
        <option value="processed">Traitée</option>
        <option value="validate">Validée</option>
      </Select>
    </FormControl>
  );
}
