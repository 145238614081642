import React from 'react';

import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    padding: '0.5rem 1rem',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '40px auto',
    gap: '1rem',

    '& > svg': {
      fill: '#6c6c6c',
      fontSize: '2.5rem',
    },
  },
  stat: {
    '& > h3': {
      margin: 0,
      color: '#6c6c6c',
      textAlign: 'right',
      fontSize: '15px',

      '& > span': {
        display: 'block',
        fontSize: '30px',
        color: '#000',
        fontWeight: 'bold',
        textAlign: 'right',
      },
    },
  },
});

export default function OrderStatCard({ stat }) {
  const classes = useStyles();

  const { title, value, Icon } = stat;

  return (
    <Paper className={classes.root}>
      <Icon />
      <section className={classes.stat}>
        <h3>
          {title}
          <span>{value}</span>
        </h3>
      </section>
    </Paper>
  );
}
