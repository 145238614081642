import React, { useState, useEffect, Fragment } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';

import moment from 'moment';
import 'moment/locale/fr';

import Cookies from 'js-cookie';

import { Skeleton } from '@material-ui/lab';
import {
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  Button,
  MenuItem,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Divider,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ADMIN_ORDER_APP_PATHS } from '../../../shared/order/constants/path.constants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '170px',
  },
  flex: {
    display: 'flex',
    marginBottom: '1rem',
    '&>*': {
      marginRight: '1rem',
    },
  },
  flexSelect: {
    display: 'flex',
    marginBottom: '1rem',
    '& > *': {
      marginRight: '1rem',
    },
  },
  customProduct: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
  },
  paper2: {
    padding: theme.spacing(2),
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  box: {
    margin: '0 1rem 1rem 0',
    maxWidth: '20%',
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  marginB: {
    marginBottom: '1rem',
  },
  marginR: {
    marginRight: '1rem',
  },
  small: {
    width: '60px',
  },
  bottom: {
    paddingTop: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    '& .MuiOutlinedInput-root': {
      marginBottom: '1rem',
    },
  },
  divider: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

function TableProducts(props) {
  return (
    <TableContainer component={Paper} className={props.classes.marginB}>
      <Table className={props.classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Nom</TableCell>
            <TableCell align="left">Catégorie</TableCell>
            <TableCell align="left">Sous-catégorie</TableCell>
            <TableCell align="left">Prix/u</TableCell>
            <TableCell align="center">Quantité</TableCell>
            <TableCell align="right">Prix/tot</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.order.items.map((item, index) => (
            <TableRow key={index}>
              <TableCell align="left">{item.name}</TableCell>
              <TableCell align="left">{item.category ? item.category : 'Hors catalogue'}</TableCell>
              <TableCell align="left">{item.subCategory ? item.subCategory : 'Hors catalogue'}</TableCell>
              <TableCell align="left">{item.price} €</TableCell>
              <TableCell align="center">
                <TextField
                  className={props.classes.small}
                  variant="outlined"
                  type="Number"
                  size="small"
                  value={props.order.items[index].quantity}
                  onChange={props.handleUpdateQuantity(index)}
                />
              </TableCell>
              <TableCell align="right">{item.price * item.quantity} €</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>
              <b>Total</b>
            </TableCell>
            <TableCell align="center">
              <b>{props.order.items.reduce((acc, curr) => acc + curr.quantity, 0)}</b>
            </TableCell>
            <TableCell align="right">
              <b>
                {Math.round(props.order.items.reduce((acc, curr) => acc + curr.quantity * curr.price, 0) * 100) / 100} €
              </b>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function AddProduct(props) {
  return (
    <Grid className={props.classes.box} item xs={3}>
      <Paper className={props.classes.paper2}>
        <h4>{props.datas.category.name}</h4>
        <FormControl variant="outlined" className={props.classes.marginB}>
          <InputLabel>Produits</InputLabel>
          <Select
            className={props.classes.marginB}
            native
            onChange={(e) => {
              const element = e.target.value;
              props.setState((prev) => ({
                ...prev,
                selector: { ...prev.selector, [props.category.name]: element },
              }));
            }}
            label="Produits"
          >
            <option value="" />
            {props.datas.subcategories
              .filter((sub) => sub.category.id === props.datas.category.id)
              .map((sub, index) => (
                <optgroup key={index} label={sub.name}>
                  {props.datas.elements
                    .filter((el) => el.subCategory._id === sub._id)
                    .map((el, index) => (
                      <option key={index} value={el._id}>
                        {el.name}
                      </option>
                    ))}
                </optgroup>
              ))}
          </Select>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() => props.handleAddProduct(props.category.name)}
          >
            Ajouter
          </Button>
        </FormControl>
      </Paper>
    </Grid>
  );
}

export default function UpdateOrder() {
  // # Setup
  moment.locale('fr');
  const classes = useStyles();
  const { id } = useParams();
  const [state, setState] = useState({
    loading: true,
    categories: [],
    subcategories: [],
    relayPoints: [],
    baskets: [],
    products: [],
    order: {},
    customProduct: {
      name: 'Custom',
      ref: 'Produit custom',
      quantity: 1,
      price: 10,
      tvaRate: '5,5',
      size: 'M',
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      const token = Cookies.get('jwt');
      const order = await axios.get(`${process.env.REACT_APP_API}/orders/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const categories = await axios.get(`${process.env.REACT_APP_API}/categories`);

      const subcategories = await axios.get(`${process.env.REACT_APP_API}/subcategories`);

      const relayPoints = await axios.get(`${process.env.REACT_APP_API}/relayPoints/?isActive=true`).then((res) =>
        res.data.data.relayPoints.map((option) => {
          const firstLetter = option.name[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            ...option,
          };
        })
      );

      const products = await axios.get(`${process.env.REACT_APP_API}/products`);

      const baskets = await axios.get(`${process.env.REACT_APP_API}/baskets`);

      setState((prev) => ({
        ...prev,
        categories: [...categories.data.data.categories],
        subcategories: [...subcategories.data.data.subCategories],
        relayPoints: [...relayPoints],
        products: [...products.data.data.products],
        baskets: [...baskets.data.data.baskets],
        loading: false,
        order: order.data.data.order,
      }));

      for (const el of categories.data.data.categories) {
        setState((prev) => ({
          ...prev,
          selector: {
            ...prev.selector,
            [el.name]: '',
          },
        }));
      }
    };
    fetchData();
  }, [id]);

  // # PREPARE PROPS ELEMENTS FOR ADDPRODUCT COMPONENT
  const setupAddproduct = (category) => {
    const { id, slug } = category;

    const formatedDatas = {};

    if (slug === 'les-paniers-bio') {
      formatedDatas.type = 'basket';
      formatedDatas.category = category;
      formatedDatas.subcategories = state.subcategories.filter((subcat) => subcat.category.id === id);
      formatedDatas.elements = state.baskets;
    } else {
      formatedDatas.type = 'product';
      formatedDatas.category = category;
      formatedDatas.subcategories = state.subcategories.filter((subcat) => subcat.category.id === id);
      formatedDatas.elements = state.products.filter((product) => product.category.id === id);
    }

    return formatedDatas;
  };

  const handleAddProduct = (name) => {
    const elem = state.selector[name];
    const arrProducts = [...state.products, ...state.baskets];
    const objProduct = arrProducts.find((el) => el._id === elem);

    const matchingProduct = state.order.items.find((el) => el.productId === elem);

    if (elem) {
      if (matchingProduct) {
        const addItem = matchingProduct;
        addItem.quantity += 1;
        const without = state.order.items.filter((el) => el.productId !== matchingProduct.productId);
        const newItems = [...without, addItem];
        setState((prev) => ({
          ...prev,
          order: {
            ...prev.order,
            items: newItems,
          },
        }));
      } else {
        setState((prev) => ({
          ...prev,
          order: {
            ...prev.order,
            items: [
              ...prev.order.items,
              {
                productId: objProduct.id,
                name: objProduct.name,
                ref: objProduct.reference,
                category: objProduct.category.name,
                subCategory: objProduct.subCategory.name,
                size: objProduct.size,
                price: objProduct.price,
                tvaRate: objProduct.tvaRate.amount,
                quantity: 1,
              },
            ],
          },
        }));
      }
    }
  };

  const handleUpdateQuantity = (index) => (e) => {
    const quantity = e.target.value * 1;
    let items = [...state.order.items];
    items[index].quantity = quantity;

    if (quantity <= 0) items = items.filter((el, idx) => idx !== index);

    setState((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        items,
      },
    }));
  };

  const handleChangeCustom = (name, value) => {
    setState((prev) => ({
      ...prev,
      customProduct: {
        ...prev.customProduct,
        [name]: value,
      },
    }));
  };

  const handleCustomProduct = () => {
    setState((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        items: [...prev.order.items, state.customProduct],
      },
    }));
  };

  const handleChangeDeliveryType = (event) => {
    const { value } = event.target;

    setState((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        deliveryInfos: {
          ...prev.order.deliveryInfos,
          deliveryAddress: {
            address: '',
            zipCode: '',
            city: '',
            country: '',
            complement: '',
            comment: '',
          },
          deliveryType: value,
          deliverySubType: '',
          deliveryId: '',
          deliveryDate: '',
          deliverySchedule: '',
        },
      },
    }));
  };

  const handleChangeDeliveryAddress = (event) => {
    const { value } = event.target;
    const matchingAddress = state.order.owner.address.find((adr) => adr._id === value);

    if (matchingAddress) {
      setState((prev) => ({
        ...prev,
        order: {
          ...prev.order,
          deliveryInfos: {
            ...prev.order.deliveryInfos,
            deliveryAddress: {
              address: matchingAddress.street,
              zipCode: matchingAddress.zipCode,
              city: matchingAddress.city,
              country: 'France',
              complement: matchingAddress.complement || '',
              comment: matchingAddress.comment || '',
            },
          },
        },
      }));
    }
  };

  const handleChangeDeliveryAddresses = (event, type) => {
    event.persist();
    const { value, name } = event.target;

    if (type === 'delivery') {
      setState((prev) => ({
        ...prev,
        order: {
          ...prev.order,
          deliveryInfos: {
            ...prev.order.deliveryInfos,
            deliveryAddress: {
              ...prev.order.deliveryInfos.deliveryAddress,
              [name]: value,
            },
          },
        },
      }));
    }

    if (type === 'billing') {
      setState((prev) => ({
        ...prev,
        order: {
          ...prev.order,
          deliveryInfos: {
            ...prev.order.deliveryInfos,
            deliveryBillingAddress: {
              ...prev.order.deliveryInfos.deliveryBillingAddress,
              [name]: value,
            },
          },
        },
      }));
    }
  };

  const handleChangeSelectRelay = (id) => {
    const matchingRelay = state.relayPoints.find((relay) => relay._id === id);

    if (matchingRelay) {
      const { type, address, name, city } = matchingRelay;

      setState((prev) => ({
        ...prev,
        order: {
          ...prev.order,
          deliveryInfos: {
            ...prev.order.deliveryInfos,
            deliveryAddress: {
              title: name,
              address: address.address,
              zipCode: city.zipCode,
              city: city.name,
              country: 'France',
              complement: address.complement || '',
              comment: address.comment || '',
            },
            deliveryType: 'relayPoint',
            deliverySubType: type,
            deliveryId: id,
          },
        },
      }));
    }
  };

  const handleChangeInfos = (event) => {
    event.persist();
    const { value, name } = event.target;

    setState((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        deliveryInfos: {
          ...prev.order.deliveryInfos,
          [name]: value,
        },
      },
    }));
  };

  const handleUpdate = async () => {
    const token = Cookies.get('jwt');

    await axios
      .patch(`${process.env.REACT_APP_API}/orders/${id}`, state.order, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        window.location = ADMIN_ORDER_APP_PATHS.ORDER_SUMMARY.replace(':orderId', id);
      })
      .catch((err) => {
        console.log(err.response);
      });
  };

  const handleChangeStatus = (event) => {
    const { value, name } = event.target;

    setState((prev) => ({
      ...prev,
      order: {
        ...prev.order,
        [name]: value,
      },
    }));
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {state.loading ? (
            <Skeleton variant="text" width={500} height={50} animation="wave" />
          ) : (
            <div className={classes.top}>
              <h1>
                Modifier la commande {id} ({state.order.owner.fullname})
              </h1>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h3>Ajouter des produits</h3>
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              {state.loading ? (
                <Skeleton variant="rect" height={300} animation="wave" />
              ) : (
                state.categories
                  .sort((a, b) => a.position - b.position)
                  .map((cat, index) => (
                    <AddProduct
                      classes={classes}
                      key={index}
                      datas={setupAddproduct(cat)}
                      category={cat}
                      subcategories={state.subcategories}
                      state={state}
                      setState={setState}
                      handleAddProduct={handleAddProduct}
                    />
                  ))
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <h3>Ajouter un produit hors catalogue</h3>
            <div className={classes.customProduct}>
              <TextField
                variant="outlined"
                type="Text"
                label="Nom"
                defaultValue={state.customProduct.name}
                name="name"
                onChange={(e) => handleChangeCustom(e.target.name, e.target.value)}
              />
              <TextField
                variant="outlined"
                type="Number"
                label="Quantité"
                defaultValue={state.customProduct.quantity}
                name="quantity"
                onChange={(e) => handleChangeCustom(e.target.name, e.target.value)}
              />
              <TextField
                variant="outlined"
                type="Number"
                label="Prix"
                defaultValue={state.customProduct.price}
                name="price"
                onChange={(e) => handleChangeCustom(e.target.name, e.target.value)}
              />
              <TextField
                variant="outlined"
                type="Text"
                label="Taux TVA"
                defaultValue={state.customProduct.tvaRate}
                name="tvaRate"
                onChange={(e) => handleChangeCustom(e.target.name, e.target.value)}
              />
              <FormControl variant="outlined">
                <InputLabel id="select-type">Taille</InputLabel>
                <Select
                  autoWidth
                  label="Taille"
                  value={state.customProduct.size}
                  name="size"
                  onChange={(e) => handleChangeCustom(e.target.name, e.target.value)}
                >
                  <MenuItem value="S">S</MenuItem>
                  <MenuItem value="M">M</MenuItem>
                  <MenuItem value="L">L</MenuItem>
                  <MenuItem value="XL">XL</MenuItem>
                  <MenuItem value="SOLO">Solo</MenuItem>
                </Select>
              </FormControl>
              <Button variant="contained" color="primary" onClick={handleCustomProduct}>
                Ajouter
              </Button>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {state.loading ? (
            <Skeleton variant="rect" height={300} animation="wave" />
          ) : (
            <Paper className={classes.paper}>
              <h3>Produits de la commande</h3>
              {state.order.items.length > 0 ? (
                <TableProducts
                  classes={classes}
                  order={state.order}
                  products={state.products}
                  handleUpdateQuantity={handleUpdateQuantity}
                />
              ) : (
                <p>Aucun produit</p>
              )}
            </Paper>
          )}
        </Grid>
        <Grid item xs={12}>
          {state.loading ? (
            <Skeleton variant="rect" height={300} animation="wave" />
          ) : (
            <Paper className={classes.paper}>
              <h3>Informations de livraison :</h3>
              <div className={classes.flexSelect}>
                <FormControl variant="outlined">
                  <InputLabel>Type de livraison</InputLabel>
                  <Select
                    autoWidth
                    label="Type de livraison"
                    value={state.order.deliveryInfos.deliveryType}
                    name="deliveryType"
                    onChange={(event) => handleChangeDeliveryType(event)}
                  >
                    <MenuItem value="home">À domicile</MenuItem>
                    <MenuItem value="relayPoint">Point de livraison</MenuItem>
                  </Select>
                </FormControl>

                {state.order.deliveryInfos.deliveryType === 'home' && (
                  <FormControl variant="outlined" className={classes.formControl}>
                    <InputLabel>Adresses</InputLabel>
                    <Select autoWidth label="Adresses" onChange={(event) => handleChangeDeliveryAddress(event)}>
                      {state.order.owner.address.length > 0 ? (
                        state.order.owner.address.map((el, index) =>
                          el.isMain ? (
                            <MenuItem
                              key={index}
                              value={el._id}
                            >{`(Principale) ${el.street} ${el.city} ${el.zipCode}`}</MenuItem>
                          ) : (
                            <MenuItem key={index} value={el._id}>{`${el.street} ${el.city} ${el.zipCode}`}</MenuItem>
                          )
                        )
                      ) : (
                        <MenuItem>Aucune adresse</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                )}

                {state.order.deliveryInfos.deliveryType === 'relayPoint' && state.relayPoints.length > 0 && (
                  <Autocomplete
                    onChange={(e, newElem) => newElem && handleChangeSelectRelay(newElem._id)}
                    options={state.relayPoints.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                    autoHighlight
                    groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => `${option.name} (${option.city.name} - ${option.city.zipCode})`}
                    style={{ width: 300 }}
                    renderInput={(params) => <TextField {...params} label="Point de livraison" variant="outlined" />}
                  />
                )}

                {state.order.deliveryInfos && state.order.deliveryInfos.deliveryAddress.zipCode && (
                  <div>
                    <TextField
                      className={classes.marginR}
                      label="Date de livraison"
                      type="date"
                      variant="outlined"
                      name="deliveryDate"
                      value={state.order.deliveryInfos.deliveryDate}
                      onChange={(event) => handleChangeInfos(event)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                    <TextField
                      label="Horaire de livraison"
                      type="Text"
                      variant="outlined"
                      name="deliverySchedule"
                      value={state.order.deliveryInfos.deliverySchedule}
                      onChange={(event) => handleChangeInfos(event)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                )}
              </div>
            </Paper>
          )}
        </Grid>
      </Grid>

      {!state.loading &&
        state.order.deliveryInfos.deliveryType &&
        state.order.deliveryInfos.deliveryDate &&
        state.order.deliveryInfos.deliverySchedule && (
          <Grid container spacing={3} className={classes.marginB}>
            <Grid item xs={9}>
              <Paper className={classes.paper}>
                <h2>Récapitulatif :</h2>
                <div>
                  <p>
                    <b>Type de livraison :</b>{' '}
                    {state.order.deliveryInfos.deliveryType === 'home' ? 'À domicile' : 'En point de livraison'}{' '}
                    {state.order.deliveryInfos.deliveryAddress.title &&
                      state.order.deliveryInfos.deliveryAddress.title !== '' &&
                      `(${state.order.deliveryInfos.deliveryAddress.title})`}{' '}
                  </p>
                  <p>
                    <b>Date de livraison :</b>{' '}
                    {moment(state.order.deliveryInfos.deliveryDate).format('dddd Do MMMM YYYY')}
                  </p>
                  <p>
                    <b>Horaire de livraison : </b>
                    {state.order.deliveryInfos.deliverySchedule}
                  </p>
                </div>
                <Grid container spacing={3}>
                  <Grid item xs={5}>
                    <form className={classes.form}>
                      <h3>Adresse de livraison :</h3>
                      <TextField
                        id="da-address"
                        label="Adresse de livraison"
                        type="text"
                        variant="outlined"
                        name="address"
                        onChange={(event) => handleChangeDeliveryAddresses(event, 'delivery')}
                        value={state.order.deliveryInfos.deliveryAddress.address}
                      />
                      <TextField
                        id="da-zipCode"
                        label="Code postal"
                        type="number"
                        variant="outlined"
                        name="zipCode"
                        onChange={(event) => handleChangeDeliveryAddresses(event, 'delivery')}
                        value={state.order.deliveryInfos.deliveryAddress.zipCode}
                      />
                      <TextField
                        id="da-city"
                        label="Ville"
                        type="text"
                        variant="outlined"
                        name="city"
                        onChange={(event) => handleChangeDeliveryAddresses(event, 'delivery')}
                        value={state.order.deliveryInfos.deliveryAddress.city}
                      />
                      <TextField
                        id="da-country"
                        label="Pays"
                        type="text"
                        variant="outlined"
                        name="country"
                        onChange={(event) => handleChangeDeliveryAddresses(event, 'delivery')}
                        value={state.order.deliveryInfos.deliveryAddress.country}
                      />
                      <TextField
                        id="da-complement"
                        label="Complément d'adresse"
                        type="text"
                        variant="outlined"
                        multiline
                        name="complement"
                        onChange={(event) => handleChangeDeliveryAddresses(event, 'delivery')}
                        value={state.order.deliveryInfos.deliveryAddress.complement}
                      />
                    </form>
                  </Grid>
                  <Grid item xs={2} className={classes.divider}>
                    <Divider orientation="vertical" />
                  </Grid>
                  <Grid item xs={5}>
                    <form className={classes.form}>
                      <h3>Adresse de facturation :</h3>
                      <TextField
                        id="df-address"
                        label="Adresse de facturation"
                        type="text"
                        variant="outlined"
                        name="address"
                        onChange={(event) => handleChangeDeliveryAddresses(event, 'billing')}
                        value={state.order.deliveryInfos.deliveryBillingAddress.address}
                      />
                      <TextField
                        id="df-zipCode"
                        label="Code postal"
                        type="number"
                        variant="outlined"
                        name="zipCode"
                        onChange={(event) => handleChangeDeliveryAddresses(event, 'billing')}
                        value={state.order.deliveryInfos.deliveryBillingAddress.zipCode}
                      />
                      <TextField
                        id="df-city"
                        label="Ville"
                        type="text"
                        variant="outlined"
                        name="city"
                        onChange={(event) => handleChangeDeliveryAddresses(event, 'billing')}
                        value={state.order.deliveryInfos.deliveryBillingAddress.city}
                      />
                      <TextField
                        id="df-country"
                        label="Pays"
                        type="text"
                        variant="outlined"
                        name="country"
                        onChange={(event) => handleChangeDeliveryAddresses(event, 'billing')}
                        value={state.order.deliveryInfos.deliveryBillingAddress.country}
                      />
                      <TextField
                        id="df-complement"
                        label="Complément d'adresse"
                        type="text"
                        variant="outlined"
                        multiline
                        name="complement"
                        onChange={(event) => handleChangeDeliveryAddresses(event, 'billing')}
                        value={state.order.deliveryInfos.deliveryBillingAddress.complement}
                      />
                    </form>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              <Paper className={classes.paper}>
                <h3>Statut de commande :</h3>
                <div className={classes.form}>
                  <FormControl variant="outlined" className={classes.marginB}>
                    <InputLabel>Statut de paiement</InputLabel>
                    <Select
                      native
                      label="Statut de paiement"
                      value={state.order.status}
                      name="status"
                      onChange={(event) => handleChangeStatus(event)}
                    >
                      <option value="waiting">En attente</option>
                      <option value="canceled">Annulé</option>
                      <option value="paid">Payé</option>
                      <option value="refuse">Refusé</option>
                      <option value="refund">Remboursé</option>
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" className={classes.marginB}>
                    <InputLabel>Statut ADV</InputLabel>
                    <Select
                      native
                      label="Statut ADV"
                      value={state.order.advStatus}
                      name="advStatus"
                      onChange={(event) => handleChangeStatus(event)}
                    >
                      <option value="input">En attente</option>
                      <option value="canceled">Annulé</option>
                      <option value="validate">Validé</option>
                      <option value="processed">Traité</option>
                      <option value="archived">Archivé</option>
                    </Select>
                  </FormControl>
                </div>
              </Paper>
            </Grid>
          </Grid>
        )}

      <div className={classes.bottom}>
        <Button variant="contained" color="primary" onClick={handleUpdate}>
          Modifier la commande
        </Button>
      </div>
    </>
  );
}
