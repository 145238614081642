import { useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import { useSnackbar } from 'notistack';

import { axiosGetRequest } from '../../../utils/axiosRequests';
import { ADMIN_SEGMENT_API_PATHS } from '../constants/path.constant';

export const useFetchSegments = () => {
  const { enqueueSnackbar } = useSnackbar();
  const token = Cookies.get('jwt');

  const [result, setResult] = useState({
    loading: true,
    segments: undefined,
  });

  useEffect(() => {
    const fetchData = () => axiosGetRequest(ADMIN_SEGMENT_API_PATHS.GET_ALL, token);

    fetchData()
      .then((response) => {
        const { segments } = response.data;

        setResult({
          loading: false,
          segments,
        });
      })
      .catch((error) => {
        const message = error.response?.data?.message ?? 'Une erreur est survenue lors de la récupération des segments';

        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
  }, [token]);

  return result;
};
