import axios from 'axios';
import { Button } from '@material-ui/core';
import React from 'react';
import Cookies from 'js-cookie';
import { BASE_ADMIN_API_ROOT_PATH } from '../../../shared/api/constants/path.constants';

export default function DownloadXlsxButton({ label, url, filename }) {
  const token = Cookies.get('jwt');

  async function handleFetchXlsx() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API}${url}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      });
      if (response.status === 'fail') throw new Error(response.message);

      const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      if (error.response) {
        console.error('Error response:', error.response.data);
        alert(`Error: ${error.response.data.message}`);
      }
    }
  }

  return (
    <Button variant="contained" color="primary" onClick={handleFetchXlsx}>
      {label}
    </Button>
  );
}
