import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import RelayDeliveryNoteSection from './RelayDeliveryNoteSection';

const useStyles = makeStyles({
  root: {
    pageBreakInside: 'avoid',
  },
});

export default function RelayDeliveryNoteTable({ deliveries }) {
  const classes = useStyles();

  const deliveriesCount = deliveries.length ?? 0;
  const isEmpty = deliveriesCount === 0;

  return (
    <div className={classes.root}>
      <h3>Livraisons en point de livraison (Total : {deliveriesCount})</h3>
      {isEmpty && <p>Aucune livraison en point de livraison</p>}
      {!isEmpty &&
        deliveries.map((delivery) => <RelayDeliveryNoteSection key={delivery.relay.deliveryId} delivery={delivery} />)}
    </div>
  );
}
