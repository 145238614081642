import React, { forwardRef, Fragment, useMemo } from 'react';
import PrintLabel from './PrintLabel';

const containerStyle = {
  color: 'black',
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  pageBreakAfter: 'always',
};

const headerStyle = {
  fontSize: '40px',
  lineHeight: '40px',
  textAlign: 'center',
  textTransform: 'uppercase',
};

function ProductLabelsContainer({ productLabels, showCategoryHeader }) {
  return (
    <>
      {showCategoryHeader && (
        <div style={containerStyle}>
          <p style={headerStyle}>{'Autres'}</p>
        </div>
      )}
      {productLabels.map((label, index) => (
        <PrintLabel key={index} label={label} />
      ))}
    </>
  );
}

function ProductLabelsByZipContainer({ productLabels, showCategoryHeader }) {
  const labelsByZip = useMemo(() => {
    return productLabels.reduce((acc, label) => {
      if (!acc[label.zip]) acc[label.zip] = [];
      acc[label.zip].push(label);
      return acc;
    }, {});
  }, [productLabels]);

  return (
    <>
      {showCategoryHeader && (
        <div style={containerStyle}>
          <p style={headerStyle}>{'Autres'}</p>
        </div>
      )}
      {Object.entries(labelsByZip).map(([zip, labels], index) => (
        <Fragment key={index}>
          <div style={containerStyle}>
            <p style={headerStyle}>{zip}</p>
          </div>
          {labels.map((label, index) => (
            <PrintLabel key={index} label={label} />
          ))}
        </Fragment>
      ))}
    </>
  );
}

function BasketLabelsContainer({ basketLabels, showCategoryHeader }) {
  return (
    <>
      {showCategoryHeader && (
        <div style={containerStyle}>
          <p style={headerStyle}>{'Les paniers bio'}</p>
        </div>
      )}
      {basketLabels.map((label, index) => (
        <PrintLabel key={index} label={label} />
      ))}
    </>
  );
}

const PrintLabelsDialog = forwardRef(function PrintLabelsDialog(
  { isOpen, labels, showCategoryHeader = true, showZipHeader = false },
  ref
) {
  const isValid = isOpen && labels;

  return (
    <>
      {isValid && (
        <div style={{ position: 'absolute', overflow: 'hidden', height: 0, width: 0 }}>
          <div ref={ref} style={{ width: '234px' }}>
            <BasketLabelsContainer showCategoryHeader={showCategoryHeader} basketLabels={labels.baskets} />

            {showZipHeader && (
              <ProductLabelsByZipContainer showCategoryHeader={showCategoryHeader} productLabels={labels.products} />
            )}
            {!showZipHeader && (
              <ProductLabelsContainer showCategoryHeader={showCategoryHeader} productLabels={labels.products} />
            )}
          </div>
        </div>
      )}
    </>
  );
});
export default PrintLabelsDialog;
