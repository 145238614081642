import React, { useState, Fragment } from 'react';

// # MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons/';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Badge,
  Link,
  IconButton,
  Collapse,
  Box,
  Typography,
} from '@material-ui/core/';

// # UTILS
import moment from 'moment';
import currencyFormater from '../../utils/currencyFormater';
import { calcTotalOrder, totalPrice } from '../../utils/totalOrders';
import { ADMIN_ORDER_APP_PATHS } from '../../shared/order/constants/path.constants';

// # LIBS
moment.locale();

const columns = [
  { id: 'details', label: '', minWidth: 50, align: 'left' },
  { id: 'identity', label: 'ID', minWidth: 100, align: 'left' },
  { id: 'owner', label: 'Client', minWidth: 100, align: 'left' },
  {
    id: 'status',
    label: 'Statut',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'adv',
    label: 'ADV',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'amount',
    label: 'Montant Payé',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'delivery',
    label: 'Date de livraison',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'created',
    label: 'Date de création',
    minWidth: 100,
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'fit-content',
  },
  delivery: {
    color: '#f05c33',
  },
});

function Row(props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell align="left">
          <Link href={ADMIN_ORDER_APP_PATHS.ORDER_SUMMARY.replace(':orderId', props.order._id)}>
            {props.order._id.substr(props.order._id.length - 5)}
          </Link>
        </TableCell>
        <TableCell align="left">
          {props.order.owner && (
            <Link href={`/admin/users/${props.order.owner._id}`}>{props.order.owner.fullname}</Link>
          )}
        </TableCell>
        <TableCell align="center">
          <Badge
            badgeContent={
              props.order.status === 'paid' ? 'Payé' : props.order.status === 'waiting' ? 'Attente' : 'Impayé'
            }
            color={props.order.status === 'paid' ? 'primary' : props.order.status === 'waiting' ? 'secondary' : 'error'}
          />
        </TableCell>
        <TableCell align="center">
          <Badge
            badgeContent={
              props.order.advStatus === 'input'
                ? 'Saisie'
                : props.order.advStatus === 'validate'
                ? 'Validée'
                : props.order.advStatus === 'processed'
                ? 'Traitée'
                : props.order.advStatus === 'archived'
                ? 'Archivée'
                : 'Annulée'
            }
            color={
              props.order.advStatus === 'archived'
                ? 'primary'
                : props.order.advStatus === 'canceled'
                ? 'secondary'
                : 'error'
            }
          />
        </TableCell>
        <TableCell align="center">{totalPrice(props.order)}</TableCell>
        <TableCell align="center">{moment(props.order.deliveryInfos.deliveryDate).format('DD-MM-YYYY')}</TableCell>
        <TableCell align="center">{moment(props.order.createdAt).format('LLL')}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" component="div">
                Détails de la commande :
              </Typography>
              <Typography variant="subtitle2" gutterBottom component="p">
                Livraison le {moment(props.order.deliveryInfos.deliveryDate).format('dddd Do MMMM YYYY')} à{' '}
                {props.order.deliveryInfos.deliverySchedule}{' '}
                {props.order.deliveryInfos.deliveryType === 'relayPoint'
                  ? 'en point de livraison'
                  : props.order.deliveryInfos.deliveryType === 'home'
                  ? 'à domicile'
                  : null}
              </Typography>
              <b style={{ fontSize: '1rem' }}>Moyen de paiement</b>
              <div>
                {props.order.partialPayment ? (
                  <>
                    <p>
                      Paiement Edenred : {currencyFormater(props.order.partialPayment.data.authorizedAmount * 0.01)}
                    </p>
                    <p>
                      Paiement Carte bancaire :{' '}
                      {currencyFormater(
                        calcTotalOrder(props.order).formatTotalTtc -
                          props.order.partialPayment.data.authorizedAmount * 0.01
                      )}
                    </p>
                  </>
                ) : (
                  <p>Paiement Carte bancaire : {totalPrice(props.order)}</p>
                )}
              </div>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Nom</TableCell>
                    <TableCell>Rubrique</TableCell>
                    <TableCell>Sous-rubrique</TableCell>
                    <TableCell align="right">Quantité</TableCell>
                    <TableCell align="right">Prix</TableCell>
                    <TableCell align="right">Prix total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.order.items.map((item, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th" scope="row">
                        {item.name}
                      </TableCell>
                      <TableCell>{item.category}</TableCell>
                      <TableCell>{item.subCategory}</TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                      <TableCell align="right">{currencyFormater(item.price)}</TableCell>
                      <TableCell align="right">{currencyFormater(item.price * item.quantity)}</TableCell>
                    </TableRow>
                  ))}
                  {props.order.discount && (
                    <TableRow>
                      <TableCell colSpan={2}>Bon de réduction</TableCell>
                      <TableCell colSpan={3}>{props.order.discount.name}</TableCell>
                      <TableCell align="right" colSpan={2}>
                        {props.order.discount.value} {props.order.discount.reduction === 'percent' ? '%' : '€'}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell className={classes.delivery} colSpan={2}>
                      Type de livraison
                    </TableCell>
                    <TableCell className={classes.delivery} colSpan={3}>
                      {props.order.deliveryInfos.deliveryType}
                    </TableCell>
                    <TableCell className={classes.delivery} align="right" colSpan={2}>
                      {props.order.deliveryCost} €
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function OrderTable(props) {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, key) =>
                key === 2 ? (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                ) : (
                  <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.orders.length > 0 ? (
              props.data.orders
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((order) => <Row key={order._id} order={order} />)
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={8}>
                  Aucune commande à cette date
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100]}
        component="div"
        count={props.data.orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
