import React from 'react';

import EcoDeliveryNoteSection from './EcoDeliveryNoteSection';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    pageBreakInside: 'avoid',
  },
});

export default function EcoDeliveryNoteTable({ deliveries }) {
  const classes = useStyles();

  const deliveriesCount = deliveries.length ?? 0;
  const isEmpty = deliveriesCount === 0;

  return (
    <div className={classes.root}>
      <h3>Livraisons Eco-acteur (Total : {deliveriesCount})</h3>
      {isEmpty && <p>Aucune livraison Eco-acteur</p>}
      {!isEmpty &&
        deliveries.map((delivery) => <EcoDeliveryNoteSection key={delivery.invitation.id} delivery={delivery} />)}
    </div>
  );
}
