import React from 'react';

export default function ThirdSection({ params }) {
  const { labelsLen } = params;

  return (
    <>
      <div>
        <p>SACS : {labelsLen}</p>
      </div>
      <div>
        <p>CAISSES :</p>
      </div>
      <div>
        <p>NOTE :</p>
      </div>
    </>
  );
}
