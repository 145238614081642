import { BASE_ADMIN_PATH } from '../../admin/constants/path.constants';

export const ADMIN_STATISTICS_ROOT = `${BASE_ADMIN_PATH}/statistics`;

export const ADMIN_STATISTICS_APP_PATHS = {
  COMMISIONS: `${ADMIN_STATISTICS_ROOT}/commissions`,
};

export const ADMIN_STATISTICS_API_PATHS = {
  COMMISSIONS: `${ADMIN_STATISTICS_ROOT}/commissions`,
};
