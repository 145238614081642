import React, { useEffect, useMemo } from 'react';
import moment from 'moment';

import { FormControl, InputLabel, Select } from '@material-ui/core';

import capitalizeFirstLetter from '../../../shared/utils/capitalize-first-letter';

export default function LastMonthsSelect({ take, selectedValue, setSelectedValue }) {
  const options = useMemo(() => {
    const formattedMonths = Array.from({ length: take }, (_, i) => {
      const month = moment().subtract(i, 'months');
      return {
        value: month.format('YYYY-MM'),
        label: capitalizeFirstLetter(month.format('MMMM YYYY')),
      };
    });

    return formattedMonths.reduce((acc, item) => {
      const year = item.value.split('-')[0];
      if (!acc[year]) acc[year] = [];
      acc[year].push(item);
      return acc;
    }, {});
  }, [take]);

  useEffect(() => {
    const optionsLen = Object.keys(options).length;
    if (!selectedValue && Boolean(optionsLen)) setSelectedValue(options[Object.keys(options)[optionsLen - 1]][0].value);
  }, [selectedValue, setSelectedValue]);

  const handleChange = (event) => setSelectedValue(event.target.value);

  return (
    <FormControl variant="outlined">
      <InputLabel>Mois</InputLabel>
      <Select native value={selectedValue} label="Mois" onChange={handleChange}>
        {Object.keys(options)
          .sort((a, b) => Number(b) - Number(a))
          .map((year) => (
            <optgroup key={`group-${year}`} label={year}>
              {options[year].map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </optgroup>
          ))}
      </Select>
    </FormControl>
  );
}
