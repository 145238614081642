import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useFirstSectionStyles = makeStyles({
  root: {
    flexBasis: '25%',
    paddingRight: '15px',
    borderRight: '1px solid #00000035',

    '& > p': {
      margin: '0 0 0.25rem 0',
    },
  },
});
function StyledFirstSection({ Content }) {
  const classes = useFirstSectionStyles();

  return <section className={classes.root}>{Content}</section>;
}

const useSecondSectionStyles = makeStyles({
  root: {
    flexBasis: '60%',
    paddingRight: '15px',
    borderRight: '1px solid #00000035',

    '& > p': {
      margin: '0 0 0.25rem 0',
    },
  },
});
function StyledSecondSection({ Content }) {
  const classes = useSecondSectionStyles();

  return <section className={classes.root}>{Content}</section>;
}

const useThirdSectionStyles = makeStyles({
  root: {
    flexBasis: '15%',
    display: 'flex',
    flexDirection: 'column',

    '& > div': {
      borderBottom: '1px solid #00000035',
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,

      '& > p': {
        margin: 0,
      },
    },
    '& > div:last-child': {
      borderBottom: 'none',
    },
  },
});
function StyledThirdSection({ Content }) {
  const classes = useThirdSectionStyles();

  return <section className={classes.root}>{Content}</section>;
}

const useStyles = makeStyles({
  header: {
    display: 'flex',
    gap: '15px',
    marginBottom: '0.25rem',
    padding: '1rem',
    border: 'solid 1px black',
    borderRadius: '5px 5px 0 0',
  },
});

export default function DeliveryNoteHeader({ FirstSection, SecondSection, ThirdSection }) {
  const classes = useStyles();

  return (
    <section className={classes.header}>
      <StyledFirstSection Content={FirstSection} />
      <StyledSecondSection Content={SecondSection} />
      <StyledThirdSection Content={ThirdSection} />
    </section>
  );
}
