import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import { Paper, Popover } from '@material-ui/core';
import { Person as PersonIcon, Payment as PaymentIcon } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@material-ui/lab';
import { formatPrice } from '../../../shared/utils/format-price';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),

    '& > h4': {
      margin: '0 0 0.5rem 0',
    },
  },

  timeLine: {
    padding: '0 0 0 0.5rem',
    marginBottom: 0,

    '& .MuiTimelineItem-missingOppositeContent:before': {
      flex: 'unset',
      padding: 0,
    },

    '& .MuiTimelineContent-root': {
      padding: '3px 16px',
      marginBottom: '1rem',
    },

    '& .MuiTimelineItem-root:last-of-type': {
      '& .MuiTimelineContent-root': {
        marginBottom: 0,
      },
    },
  },

  timelineContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',

    '& > section': {
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',

        '& .MuiSvgIcon-root': {
          width: '15px',
          height: '15px',
        },
      },
    },

    '& p': {
      margin: 0,
    },
  },
}));

const getStatusColor = (status) => {
  switch (status) {
    case 'abandoned':
      return '#f0f0f0';
    case 'canceled':
      return '#f44336';
    case 'paid':
      return '#4caf50';
    case 'refuse':
      return '#ff9800';
    case 'waiting':
      return '#2196f3';
    case 'refund':
      return '#8e44ad';
    case 'partial':
      return '#f1c40f';
    default:
      return '#f0f0f0';
  }
};
export default function LastOrdersTimeline() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => setAnchorEl(event.currentTarget);

  const handlePopoverClose = () => setAnchorEl(null);

  const loading = false;

  const data = [
    {
      id: '124e4567-e89b-12d3-a456-426614174000',
      owner: 'John Doe',
      status: 'waiting',
      total: 100,
    },
    {
      id: '123e4567-e89b-12d3-a456-426614174001',
      owner: 'Jane Doe',
      status: 'paid',
      total: 100,
    },
    {
      id: '125e4567-e89b-12d3-a456-426614174003',
      owner: 'Max Doe',
      status: 'canceled',
      total: 100,
    },
    {
      id: '126e4567-e89b-12d3-a456-426614174003',
      owner: 'Ben Doe',
      status: 'refuse',
      total: 100,
    },
    {
      id: '120e4567-e89b-12d3-a456-426614174003',
      owner: 'Jo Doe',
      status: 'abandoned',
      total: 100,
    },
    {
      id: '329e4567-e89b-12d3-a456-426614174003',
      owner: 'Simon Doe',
      status: 'paid',
      total: 100,
    },
    {
      id: '111e4567-e89b-12d3-a456-426614174003',
      owner: 'Paul Doe',
      status: 'paid',
      total: 100,
    },
    {
      id: '121e4567-e89b-12d3-a456-426614174003',
      owner: 'George Doe',
      status: 'paid',
      total: 100,
    },
  ];

  return (
    <>
      {loading && <Skeleton variant="rect" width={500} height={150} />}
      {!loading && (
        <Paper className={classes.root}>
          <h4>Dernières commandes</h4>
          <section>
            <Timeline className={classes.timeLine}>
              {data.map((order, index) => (
                <TimelineItem key={order.id}>
                  <TimelineSeparator>
                    <TimelineDot
                      variant="outlined"
                      style={{ borderColor: getStatusColor(order.status) }}
                      onMouseEnter={handlePopoverOpen}
                      onMouseLeave={handlePopoverClose}
                    />
                    {index + 1 !== data.length && <TimelineConnector />}
                  </TimelineSeparator>
                  <TimelineContent className={classes.timelineContent}>
                    <p>{`#${order.id.substring(0, 8)}`}</p>
                    <section>
                      <div>
                        <PersonIcon />
                        <p>{order.owner}</p>
                      </div>
                      <div>
                        <PaymentIcon />
                        <p>{formatPrice(order.total)}</p>
                      </div>
                    </section>
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          </section>
          <Popover
            style={{ pointerEvents: 'none', padding: 0 }}
            classes={{
              paper: classes.paper,
            }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'bottom',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <p style={{ margin: 0 }}>I use Popover.</p>
          </Popover>
        </Paper>
      )}
    </>
  );
}
