export const formatAdvStatus = (advStatus) => {
  switch (advStatus) {
    case 'input':
      return 'en cours';
    case 'validate':
      return 'validée';
    case 'processed':
      return 'traitée';
    case 'archived':
      return 'archivée';
    case 'canceled':
      return 'annulée';
    default:
      return 'inconnue';
  }
};
export const formatPaymentStatus = (paymentStatus) => {
  switch (paymentStatus) {
    case 'abandoned':
      return 'Abandonnée';
    case 'canceled':
      return 'Annulée';
    case 'paid':
      return 'Payée';
    case 'refuse':
      return 'Refusée';
    case 'waiting':
      return 'En attente';
    case 'refund':
      return 'Remboursée';
    case 'partial':
      return 'Partielle';
    default:
      return 'Inconnue';
  }
};
