import React from 'react';

import TagManager from 'react-gtm-module';

import { Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

// # LAYOUTS
import Front from './layouts/Front';
import EcoActeurs from './layouts/EcoActeurs';
import BlogLayout from './layouts/BlogLayout';

// # PAGES FRONT
//! PAYMENT
import Signup from './views/Front/Profil/Signup';
import Login from './views/Front/Profil/Login';
import ForgotPassword from './views/Front/Profil/ForgotPassword';
import ResetPassword from './views/Front/Profil/ResetPassword';
import Valeurs from './views/Front/NosValeurs/Valeurs';
import NosProducteurs from './views/Front/NosValeurs/NosProducteurs';
import NosProduits from './views/Front/NosValeurs/NosProduits';
import ZeroDechets from './views/Front/NosValeurs/ZeroDechets';
import HomeBis from './views/Front/HomeBis';
import PageProduits from './views/Front/PageProduits';
import PaiementSuccess from './views/Front/AfterPayment/success';
import PaiementCancel from './views/Front/AfterPayment/cancel';
import EcoPoints from './views/Front/EcoActeurs/EcoPoints';
import EcoActeur from './views/Front/EcoActeurs/DevenirEcoActeur';
import CommentCaMarche from './views/Front/EcoActeurs/CCM';
import Profil from './views/Front/Profil/Profil';
import OffreEntreprise from './views/Front/Entreprises/OffreEntreprise';
import CorbeilleFruit from './views/Front/Entreprises/CorbeilleFruit';
import MentionsLegales from './views/Front/MentionsLegales';
import cgv from './views/Front/CGV';
import ContactEntreprise from './views/Front/Entreprises/ContactEntreprise';
import Invitation from './views/Front/NavbarEcoActeur/CreateInvitation';
import InvitationList from './views/Front/NavbarEcoActeur/InvitationList';
import CreateContact from './views/Front/NavbarEcoActeur/CreateContact';
import VosEcoPoints from './views/Front/NavbarEcoActeur/VosEcoPoints';
import ContactList from './views/Front/NavbarEcoActeur/ContactList';
import Contact from './views/Front/Contact';
import DashboardEco from './views/Front/NavbarEcoActeur/Dashboard';
import UpdateInvitation from './views/Front/NavbarEcoActeur/UpdateInvitation';
import Products from './views/EcoActeurs/Products';
import EcoInvit from './views/EcoActeurs/InvitationSession';

import notFound from './views/404';
import ExpiredInvitation from './views/ExpiredInvitation';
import Offre from './views/Front/Offre';
import Article from './views/Front/Blog/Article';
import FilterArticles from './views/Front/Blog/ArticlesFiltered';
import BlogHome from './views/Front/Blog/BlogHome';
import PoireauDefense from './views/Front/PoireauDefense';
import CarottesDefense from './views/Front/CarottesDefense';
import AppRoute from './shared/routes/AppRoute';
import AdminRoutes from './routes/admin';

const history = createBrowserHistory();

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ACCOUNT,
});

function App() {
  return (
    <Router history={history}>
      <Switch>
        {/* FRONT */}
        <AppRoute exact path="/" layout={Front} component={HomeBis} />
        <AppRoute exact path="/home" layout={Front} component={HomeBis} />
        <AppRoute exact path="/valeurs" layout={Front} component={Valeurs} />
        <AppRoute exact path="/nos-producteurs" layout={Front} component={NosProducteurs} />
        <AppRoute exact path="/contact" layout={Front} component={Contact} />
        <AppRoute exact path="/zero-dechets" layout={Front} component={ZeroDechets} />
        <AppRoute exact path="/eco-points" layout={Front} component={EcoPoints} />
        <AppRoute exact path="/eco-acteur" layout={Front} component={EcoActeur} />
        <AppRoute exact path="/c-c-m" layout={Front} component={CommentCaMarche} />
        <AppRoute exact path="/profil" layout={Front} component={Profil} />
        <AppRoute exact path="/offre-entreprise" layout={Front} component={OffreEntreprise} />
        <AppRoute exact path="/corbeille-fruit" layout={Front} component={CorbeilleFruit} />
        <AppRoute exact path="/mentions-legales" layout={Front} component={MentionsLegales} />
        <AppRoute exact path="/cgv" layout={Front} component={cgv} />
        <AppRoute exact path="/nos-valeurs" layout={Front} component={Valeurs} />
        <AppRoute exact path="/nos-producteurs" layout={Front} component={NosProducteurs} />
        <AppRoute exact path="/nos-produits" layout={Front} component={NosProduits} />
        <AppRoute exact path="/zero-dechets" layout={Front} component={ZeroDechets} />
        <AppRoute exact path="/success/:id" layout={Front} component={PaiementSuccess} />
        <AppRoute exact path="/cancel" layout={Front} component={PaiementCancel} />
        <AppRoute exact path="/catalogue/:slug" layout={Front} component={PageProduits} />
        <AppRoute exact path="/offre-decouverte" layout={Front} component={Offre} code={'BIO-2022'} />
        <AppRoute exact path="/offre-rentree-bio" layout={Front} component={Offre} code={'RENTREEBIO'} />
        <AppRoute exact path="/offre-2022-bio" layout={Front} component={Offre} code={'2022-BIO'} />
        <AppRoute exact path="/poireau-ladefense" layout={Front} component={PoireauDefense} code={'POIREAU'} />
        <AppRoute exact path="/carottes-ladefense" layout={Front} component={CarottesDefense} code={'CAROTTES'} />
        <AppRoute exact path="/contact-entreprise" layout={Front} component={ContactEntreprise} />
        <AppRoute exact path="/create_invitation" layout={Front} component={Invitation} />
        <AppRoute exact path="/invitations" layout={Front} component={InvitationList} />
        <AppRoute exact path="/expired_invitations" layout={Front} component={ExpiredInvitation} />
        <AppRoute exact path="/create_contact" layout={Front} component={CreateContact} />
        <AppRoute exact path="/contact_list" layout={Front} component={ContactList} />
        <AppRoute exact path="/vos-eco-points" layout={Front} component={VosEcoPoints} />
        <AppRoute exact path="/invitation/:invitationId" layout={Front} component={DashboardEco} />
        <AppRoute exact path="/update_invitation/:invitationId" layout={Front} component={UpdateInvitation} />
        <AppRoute exact path="/signup" layout={Front} component={Signup} />
        <AppRoute exact path="/forgotpassword" layout={Front} component={ForgotPassword} />
        <AppRoute exact path="/resetPassword/:token" layout={Front} component={ResetPassword} />
        <AppRoute exact path="/login" layout={Front} component={Login} />

        {/*FRONT BLOG*/}
        <AppRoute exact path="/blog" layout={BlogLayout} component={BlogHome} />
        <AppRoute exact path="/blog/:slug" layout={BlogLayout} component={Article} />
        <AppRoute exact path="/blog/filter/:tag" layout={BlogLayout} component={FilterArticles} />

        {/*FRONT ECO ACTEURS*/}
        <AppRoute exact path="/eco-invitation/:uuid" layout={EcoActeurs} component={EcoInvit} />
        <AppRoute exact path="/eco-invite" layout={EcoActeurs} component={Products} />
        <AppRoute exact path="/eco-catalogue/:slug" layout={EcoActeurs} component={Products} />
        <AppRoute exact path="/eco-values" layout={EcoActeurs} component={Valeurs} />
        <AppRoute exact path="/eco-productors" layout={EcoActeurs} component={NosProducteurs} />
        <AppRoute exact path="/eco-products" layout={EcoActeurs} component={NosProduits} />
        <AppRoute exact path="/eco-zero-dechets" layout={EcoActeurs} component={ZeroDechets} />

        {/* BACK ADMIN */}
        <AdminRoutes />

        {/* NOTHING MATCHING - 404 */}
        <AppRoute layout={Front} component={notFound} />
      </Switch>
    </Router>
  );
}

export default App;
