import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import { calcTotalOrder } from '../../../../utils/totalOrders';
import currencyFormater from '../../../../utils/currencyFormater';
import { ORDER_STATUS } from '../../../../shared/order/constants';

function RowFirstSection({ title, fullName, phoneNumber }) {
  return (
    <section className="firstSection">
      <p style={{ marginBottom: '0.5rem' }}>
        <b>{title}</b>
      </p>
      <p>{fullName}</p>
      <p>{phoneNumber}</p>
    </section>
  );
}
function RowSecondSection({ items }) {
  const itemsCount = items.reduce((acc, curr) => acc + curr.quantity, 0);
  return (
    <section className="secondSection">
      <Chip style={{ borderRadius: '5px' }} label={itemsCount} color="secondary" />
      <ul>
        {items.map((item, index) => (
          <li key={`${item.id}-${index}`}>
            {item.name} <b style={{ fontSize: '18px' }}>x{item.quantity}</b>
          </li>
        ))}
      </ul>
    </section>
  );
}
function RowThirdSection({ order }) {
  const { status } = order;
  const { formatTotalTtc } = calcTotalOrder(order);

  return (
    <section className="thirdSection">
      <p style={{ fontSize: '15px' }}>{currencyFormater(formatTotalTtc)}</p>
      <p>{ORDER_STATUS[status]}</p>
    </section>
  );
}

const useOrderRowStyle = makeStyles(() => ({
  root: (props) => ({
    display: 'flex',
    gap: '15px',
    marginBottom: '0.25rem',
    padding: '1rem',
    border: 'solid 1px black',
    borderRadius: props.isLast ? '0 0 5px 5px' : '0',

    '& p': {
      margin: '0 0 0.25rem 0',
    },

    '& > .firstSection': {
      flexBasis: '25%',
      paddingRight: '15px',
    },

    '& > .secondSection': {
      flexBasis: '60%',
      paddingRight: '15px',

      display: 'flex',
      alignItems: 'center',
    },

    '& > .firstSection, & > .secondSection': {
      borderRight: '1px solid #00000035',
    },

    '& > .thirdSection': {
      flexBasis: '15%',

      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  }),
}));

function OrderRow({ order, ordersLen, currIdx }) {
  const classes = useOrderRowStyle({ isLast: ordersLen === currIdx });

  const { owner, items } = order;

  return (
    <section className={classes.root}>
      <RowFirstSection
        title={`Commande ${currIdx}/${ordersLen}`}
        fullName={`${owner.lastname} ${owner.firstname}`}
        phoneNumber={owner.phoneNumber}
      />
      <RowSecondSection items={items} />
      <RowThirdSection order={order} />
    </section>
  );
}

export default function DeliveryNoteList({ orders }) {
  const ordersLen = orders.length;
  return (
    <section>
      {orders.map((order, index) => (
        <OrderRow key={order.id} order={order} ordersLen={ordersLen} currIdx={index + 1} />
      ))}
    </section>
  );
}
