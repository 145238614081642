import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { axiosGetRequest } from '../../../utils/axiosRequests';
import { ADMIN_RELAYS_API_PATHS } from '../constants/path.constants';

export const useGetRelays = ({ segmentId = undefined }) => {
  const { enqueueSnackbar } = useSnackbar();
  const token = Cookies.get('jwt');

  const defaultState = {
    loading: true,
    relays: undefined,
  };

  const [result, setResult] = useState(defaultState);

  useEffect(() => {
    setResult(defaultState);

    let url = ADMIN_RELAYS_API_PATHS.LIST;

    if (segmentId) url += `?segment=${segmentId}`;

    const fetchData = () => axiosGetRequest(url, token);

    fetchData()
      .then((response) => {
        const { relays } = response.data;

        setResult({
          loading: false,
          relays,
        });
      })
      .catch((error) => {
        const message =
          error.response?.data?.message ?? 'Une erreur est survenue lors de la récupération des points relay';

        enqueueSnackbar(message, {
          variant: 'error',
        });
      });
  }, [token, segmentId, enqueueSnackbar]);

  return result;
};
