import React, { Fragment } from 'react';

function PrintLabelHeader({ title, deliveryDate, deliveryCity, owner }) {
  return (
    <>
      {title && (
        <>
          <p
            style={{
              fontSize: '15px',
              lineHeight: '15px',
              margin: '0',
            }}
          >
            {title}
          </p>
          <hr
            style={{
              border: 'none',
              backgroundColor: 'black',
              height: '2px',
              width: '50%',
              margin: '10px auto 15px auto',
            }}
          />
        </>
      )}
      <p
        style={{
          fontSize: '15px',
          lineHeight: '15px',
          margin: '0 0 10px 0',
        }}
      >
        {deliveryDate}
      </p>
      <p
        style={{
          fontSize: '15px',
          lineHeight: '15px',
          margin: '0',
        }}
      >
        {deliveryCity}
      </p>
      <hr
        style={{
          border: 'none',
          backgroundColor: 'black',
          height: '2px',
          width: '50%',
          margin: '10px auto 15px auto',
        }}
      />
      <p
        style={{
          fontSize: '16px',
          lineHeight: '16px',
          margin: '0',
        }}
      >
        <b>{owner}</b>
      </p>
      <hr
        style={{
          border: 'none',
          backgroundColor: 'black',
          height: '2px',
          width: '50%',
          margin: '10px auto 25px auto',
        }}
      />
    </>
  );
}

function PrintLabelBody({ products, isCheckable }) {
  return (
    <>
      {products.map((product, index) => (
        <Fragment key={index}>
          <p
            style={{
              fontSize: '16px',
              lineHeight: '17px',
              margin: '10px 0 10px 0',
            }}
          >
            {isCheckable && (
              <span
                style={{
                  fontSize: '30px',
                  lineHeight: '33px',
                }}
              >
                {'\u25A1'}{' '}
              </span>
            )}
            {product.title}
            {product.quantity > 1 && (
              <b
                style={{
                  fontSize: '30px',
                  lineHeight: '33px',
                }}
              >
                {` x${product.quantity}`}
              </b>
            )}
          </p>
          <hr
            style={{
              border: 'none',
              backgroundColor: 'black',
              height: '1px',
              width: '20%',
              margin: '0 auto 10px auto',
            }}
          />
        </Fragment>
      ))}
    </>
  );
}

export default function PrintLabel({ label, isCheckable = false }) {
  return (
    <div
      style={{
        color: 'black',
        textAlign: 'center',
        pageBreakAfter: 'always',
      }}
    >
      <PrintLabelHeader
        title={label.title}
        deliveryDate={`${label.deliveryDate} (${label.schedule})`}
        deliveryCity={`${label.zip} - ${label.city.toUpperCase()}`}
        owner={label.ownerFullname}
      />
      <PrintLabelBody products={label.products} isCheckable={isCheckable} />
    </div>
  );
}
