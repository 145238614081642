import React, { useMemo, useState } from 'react';

import {
  Badge,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import {
  ExitToApp as ExitToAppIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons';
import { ADMIN_ORDER_APP_PATHS } from '../../../../../shared/order/constants/path.constants';
import { makeStyles } from '@material-ui/core/styles';
import OrderHeaderSummary from '../../../../../components/Order/Delivery/OrderDeliverySummary';
import { useGetOrderHeaders } from '../../../../../shared/order/hooks/order.hooks';
import { formatPrice } from '../../../../../shared/utils/format-price';

const useStyles = makeStyles({
  root: (props) => ({
    '& > .MuiTableCell-root': props.isOpen
      ? { padding: '0.5rem', borderBottom: '1px solid rgba(224, 224, 224, 1)' }
      : {
          padding: '0 0.5rem',
          borderBottom: 0,
        },
  }),

  orderRow: (props) => ({
    '& > .MuiTableRow-root': {
      backgroundColor: props.isOpen ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
    },

    '& > .MuiTableCell-root': {
      borderBottom: props.isOpen ? 0 : '1px solid rgba(224, 224, 224, 1)',
    },
  }),

  collapse: {
    '& .MuiPaper-root': {
      padding: '1rem',

      '& > h4': {
        margin: 0,
      },
    },
  },

  summary: {
    marginBottom: '0.5rem',
  },

  itemsFooter: {
    '& .MuiTableCell-root': {
      borderBottom: 0,
      color: '#f44336',
    },
  },
});

function OrderItemsSummaryTable({ order }) {
  const classes = useStyles();

  const { items, deliveryInfos } = order;

  const deliveryCost = deliveryInfos.deliveryCost || 0;

  const headers = [
    {
      key: 'name',
      label: 'Produit',
    },
    {
      key: 'category',
      label: 'Rubrique',
    },
    {
      key: 'subCategory',
      label: 'Sous-rubrique',
    },
    {
      key: 'label',
      label: 'Étiquette',
      align: 'center',
    },
    {
      key: 'quantity',
      label: 'Quantité',
      align: 'right',
    },
    {
      key: 'price',
      label: 'Prix/u',
      align: 'right',
    },
    {
      key: 'total',
      label: 'Prix total',
      align: 'right',
    },
  ];

  return (
    <Table size="small" stickyHeader>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell style={{ fontWeight: 'bold' }} key={header.key} align={header.align}>
              {header.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item._id}>
            <TableCell>{item.name}</TableCell>
            <TableCell>{item.category}</TableCell>
            <TableCell>{item.subCategory}</TableCell>
            <TableCell align="center">
              <Badge color={item.noLabel ? 'secondary' : 'primary'} variant="dot" />
            </TableCell>
            <TableCell align="right">{item.quantity}</TableCell>
            <TableCell align="right">{formatPrice(item.price)}</TableCell>
            <TableCell align="right">{formatPrice(item.price * item.quantity)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter className={classes.itemsFooter}>
        <TableRow>
          <TableCell colSpan={4} />
          <TableCell align="right">Frais de livraison</TableCell>
          <TableCell align="right">{deliveryCost > 0 ? formatPrice(deliveryCost) : 'Aucun'}</TableCell>
        </TableRow>
      </TableFooter>
    </Table>
  );
}

function OrderSummaryCollapse({ isOpen, colSpan, order }) {
  const classes = useStyles({ isOpen });

  const orderHeaders = useGetOrderHeaders(order);

  return (
    <TableRow className={classes.root} tabIndex={-1}>
      <TableCell colSpan={colSpan}>
        <Collapse className={classes.collapse} in={isOpen} timeout="auto" unmountOnExit>
          <OrderHeaderSummary headers={Object.values(orderHeaders)} />
          <OrderItemsSummaryTable order={order} />
        </Collapse>
      </TableCell>
    </TableRow>
  );
}

function OrderTableRow({ order, cells }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles({ isOpen: open });

  const href = ADMIN_ORDER_APP_PATHS.ORDER_SUMMARY.replace(':orderId', order.id);

  const handleOpen = () => setOpen((prev) => !prev);

  return (
    <React.Fragment>
      <TableRow className={classes.orderRow} hover={!open} tabIndex={-1}>
        <TableCell align="left" style={{ maxWidth: 50 }}>
          <IconButton aria-label="show" onClick={handleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="center" style={{ maxWidth: 50 }}>
          <IconButton aria-label="show" target="_blank" href={href}>
            <ExitToAppIcon />
          </IconButton>
        </TableCell>
        {cells.map((cell) => (
          <TableCell align={cell.align} style={{ minWidth: cell.minWidth }} key={`cell-${cell.key}`}>
            {cell.content}
          </TableCell>
        ))}
      </TableRow>
      <OrderSummaryCollapse isOpen={open} colSpan={cells.length + 2} order={order} />
    </React.Fragment>
  );
}

function OrderTableBody({ rows }) {
  return (
    <TableBody>
      {rows.map((row, idx) => (
        <OrderTableRow key={idx} order={row.order} cells={row.cells} />
      ))}
    </TableBody>
  );
}

function OrderTableEmptyBody({ headers }) {
  return (
    <TableBody>
      <TableRow>
        <TableCell colSpan={headers.length + 2} style={{ textAlign: 'center' }}>
          Aucune commande
        </TableCell>
      </TableRow>
    </TableBody>
  );
}

function OrderTableHeader({ headers }) {
  return (
    <TableHead>
      <TableRow>
        <TableCell align="left" style={{ maxWidth: 50 }} />
        <TableCell align="center" style={{ maxWidth: 50 }} />
        {headers.map((header) => (
          <TableCell key={header.key} align={header.align} style={{ minWidth: header.minWidth, fontWeight: 'bold' }}>
            {header.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function OrderTableWithDetails({ headers, orders, tableMaxHeight = '500px' }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const hasOrders = !!orders.length;

  const handlePageChange = (event, newPage) => setPage(newPage);

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleOrders = useMemo(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;

    return orders.slice(start, end);
  }, [orders, page, rowsPerPage]);

  return (
    <Paper>
      <TableContainer style={{ maxHeight: tableMaxHeight }}>
        <Table stickyHeader>
          <OrderTableHeader headers={headers} />
          {hasOrders && <OrderTableBody rows={visibleOrders} />}
          {!hasOrders && <OrderTableEmptyBody headers={headers} />}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 200]}
        component="div"
        count={orders.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  );
}
