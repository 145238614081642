import React from 'react';

export default function SecondSection({ params }) {
  const { title, address, city, zipCode, relayPhone, complement, comment } = params;
  return (
    <>
      <p>
        <b>Point de livraison :</b> {title}
      </p>
      <p>
        <b>Adresse : </b>
        {address} - {city} {zipCode}
      </p>
      {relayPhone && (
        <p>
          <b>Téléphone :</b> {relayPhone}
        </p>
      )}
      {complement && (
        <p>
          <b>Complément : </b>
          <br />
          {complement}
        </p>
      )}
      {comment && (
        <p>
          <b>Commentaire : </b>
          <br />
          {comment}
        </p>
      )}
    </>
  );
}
