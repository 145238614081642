import React, { useMemo } from 'react';

import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatAdvStatus } from '../../../../shared/order/utils/format-status';
const getChipStyle = (advStatus) => {
  switch (advStatus) {
    case 'input':
      return { backgroundColor: '#f0f0f0', color: '#000000' }; // Light Grey
    case 'validate':
      return { backgroundColor: '#ff9800', color: '#ffffff' }; // Orange
    case 'processed':
      return { backgroundColor: '#2196f3', color: '#ffffff' }; // Blue
    case 'archived':
      return { backgroundColor: '#4caf50', color: '#ffffff' }; // Green
    case 'canceled':
      return { backgroundColor: '#f44336', color: '#ffffff' }; // Red
    default:
      return { backgroundColor: '#f0f0f0', color: '#000000' }; // Light Grey
  }
};

const useStyles = makeStyles(() => ({
  root: (props) => ({
    borderRadius: 4,
    textTransform: 'capitalize',
    backgroundColor: props.backgroundColor,
    color: props.color,
  }),
}));

export default function AdvStatusChip({ advStatus, size = 'small' }) {
  const { label, backgroundColor, color } = useMemo(() => {
    return {
      label: formatAdvStatus(advStatus),
      ...getChipStyle(advStatus),
    };
  }, [advStatus]);

  const classes = useStyles({ backgroundColor, color });

  return <Chip className={classes.root} label={label} size={size} />;
}
