import React from 'react';

import EcoDeliveryNoteTable from './eco/EcoDeliveryNoteTable';
import RelayDeliveryNoteTable from './relay/RelayDeliveryNoteTable';
import HomeDeliveryNoteTable from './home/HomeDeliveryNoteTable';

const style = {
  root: {
    maxWidth: '95%',
    margin: '0 auto',
  },
};

export default class PrintList extends React.Component {
  render() {
    return (
      <div style={{ ...style.root }}>
        <div>
          {this.props.orders.home.length > 0 && (
            <>
              <h1>Livraisons à domicile ({this.props.orders.home.length} commandes)</h1>
              <HomeDeliveryNoteTable deliveries={this.props.orders.home} />
            </>
          )}

          {this.props.orders.relay.length > 0 && (
            <div style={{ pageBreakBefore: 'always' }}>
              <h1>Livraisons en point de livraison ({this.props.orders.relay.length} commandes)</h1>
              <RelayDeliveryNoteTable deliveries={this.props.orders.relay} />
            </div>
          )}

          {this.props.orders.eco.length > 0 && (
            <div style={{ pageBreakBefore: 'always' }}>
              <h1>Livraisons Eco-acteurs ({this.props.orders.eco.length} commandes)</h1>
              <EcoDeliveryNoteTable deliveries={this.props.orders.eco} />
            </div>
          )}
        </div>
      </div>
    );
  }
}
