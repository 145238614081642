import React, { useState, useEffect, Fragment } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

// MATERIAL UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Link,
  Typography,
  IconButton,
  Box,
  Collapse,
  Badge,
} from '@material-ui/core';

import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';

import moment from 'moment';
import { calcTotalOrder, totalPricesTable } from '../../utils/totalOrders';

import currencyFormater from '../../utils/currencyFormater';
import { axiosGetRequest } from '../../utils/axiosRequests';
import { ADMIN_ORDER_APP_PATHS } from '../../shared/order/constants/path.constants';

moment.locale();

const columns = [
  { id: 'action', label: '', minWidth: 50, align: 'left' },
  { id: 'id', label: 'ID', minWidth: 50, align: 'left' },
  { id: 'ecoactor', label: 'Eco Acteur', minWidth: 100, align: 'left' },
  { id: 'participant', label: 'Participants', minWidth: 100, align: 'center' },
  { id: 'date', label: 'Date', minWidth: 100, align: 'center' },
  {
    id: 'cost',
    label: 'Montant Total',
    minWidth: 100,
    align: 'center',
  },
  {
    id: 'deliveryDate',
    label: 'Date de livraison',
    minWidth: 100,
    align: 'center',
  },
];

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 'fit-content',
  },
  marginT: {
    marginTop: '1rem',
  },
  delivery: {
    color: '#f05c33',
  },
});

function OrderRow(props) {
  const { order } = props;

  return (
    <TableRow>
      <TableCell>
        <Link href={ADMIN_ORDER_APP_PATHS.ORDER_SUMMARY.replace(':orderId', props.order._id)}>
          {props.order.id.slice(-5)}
        </Link>
      </TableCell>
      <TableCell>
        <Link href={`/admin/users/${props.order.owner._id}`}>{props.order.owner.fullname}</Link>
      </TableCell>
      <TableCell align="center">
        <Badge
          badgeContent={order.status === 'paid' ? 'Payé' : order.status === 'waiting' ? 'Attente' : 'Impayé'}
          color={order.status === 'paid' ? 'primary' : order.status === 'waiting' ? 'secondary' : 'error'}
        />
      </TableCell>
      <TableCell>
        <Badge
          badgeContent={
            order.advStatus === 'input'
              ? 'Saisie'
              : order.advStatus === 'validate'
              ? 'Validée'
              : order.advStatus === 'processed'
              ? 'Traitée'
              : order.advStatus === 'archived'
              ? 'Archivée'
              : 'Annulée'
          }
          color={order.advStatus === 'archived' ? 'primary' : order.advStatus === 'canceled' ? 'secondary' : 'error'}
        />
      </TableCell>
      <TableCell>{currencyFormater(calcTotalOrder(order).formatTotalTtc)}</TableCell>
    </TableRow>
  );
}

function Row(props) {
  const { _id, sender, deliveryInfos, createdAt, contacts } = props.invitation;
  const { orders } = props;
  const totalPrices = orders ? totalPricesTable(orders) : 0;
  const totalInvit = orders
    ? orders
        .filter((order) => order.status === 'paid')
        .reduce(
          (acc, curr) =>
            acc +
            calcTotalOrder(curr).formatTotalTtc -
            calcTotalOrder(curr).formatTotalDiscountAmount -
            calcTotalOrder(curr).formatTotalCompanyDiscountAmount,
          0
        )
    : 0;

  const [open, setOpen] = useState(false);

  const classes = useStyles();

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          <Link href={`/admin/invitation/${_id}`}>{_id.slice(-5)}</Link>
        </TableCell>
        <TableCell>
          <Link>{sender && sender.fullname}</Link>
        </TableCell>
        <TableCell align="center">
          {' '}
          {orders.length}/{contacts.length}
        </TableCell>
        <TableCell align="center">{moment(createdAt).format('DD-MM-YYYY')}</TableCell>
        <TableCell align="center">{currencyFormater(totalInvit)}</TableCell>
        <TableCell align="center">
          {moment(deliveryInfos.deliveryDate).format('DD-MM-YYYY')} ({deliveryInfos.deliverySchedule})
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Typography variant="h5" component="div" className={classes.marginT}>
              Détails des commandes :
            </Typography>
            <Typography component="div">
              Adresse de livraison : {deliveryInfos.deliveryAddress.address} - {deliveryInfos.deliveryAddress.city} -{' '}
              {deliveryInfos.deliveryAddress.zipCode} - {deliveryInfos.deliveryAddress.country}
            </Typography>
            <Box margin={1}>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Contact</TableCell>
                    <TableCell align="center">Statut</TableCell>
                    <TableCell align="center">ADV Statut</TableCell>
                    <TableCell>Montant Payé</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.length ? (
                    orders.map((order, idx) => <OrderRow totalPrice={totalPrices[idx]} key={idx} order={order} />)
                  ) : (
                    <TableRow>
                      <TableCell align="center" colSpan={6}>
                        Pas de commandes
                      </TableCell>
                    </TableRow>
                  )}
                  {/* order.items.map((item) => (
                  <TableRow>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.category}</TableCell>
                    <TableCell>{item.subCategory}</TableCell>
                    <TableCell>{item.quantity}</TableCell>
                  </TableRow>
                  )) */}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default function InvitationsTable(props) {
  const classes = useStyles();
  // const invitations = props.invitations.sort((a, b) => b.createdAt.localeCompare() - a.createdAt);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [state, setState] = useState({
    invitations: [],
    totalLength: 0,
    orders: [],
  });

  useEffect(() => {
    const token = Cookies.get('jwt');
    axiosGetRequest(`/invitations/withorders/?page=${page + 1}&limit=${rowsPerPage}`, token)
      .then((res) => {
        const { invitations, totalLength, orders } = res.data.data;
        setState((prev) => ({
          ...prev,
          invitations,
          totalLength,
          orders,
        }));
      })
      .catch((err) => {
        console.log(err.response);
      });
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API}/invitations/withorders/?page=${page + 1}&limit=${rowsPerPage}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const { invitations, totalLength, orders } = res.data.data;
          setState((prev) => ({
            ...prev,
            invitations,
            totalLength,
            orders,
          }));
        })
        .catch((err) => {
          console.log(err.response);
        });
    };
    fetchData();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((el, key) => (
                <TableCell key={key} align={el.align}>
                  {el.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {state.invitations.map((invitation, key) => (
              <Row
                key={key}
                invitation={invitation}
                orders={state.orders.filter((order) => order.invitation.id === invitation.id) || []}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[20, 50, 100]}
        component="div"
        count={state.totalLength}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
