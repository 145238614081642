import React, { useEffect, useMemo } from 'react';
import { useGetDeliveryCityByZip } from '../../../../../shared/delivery/hooks/delivery.hooks';
import { getDeliveryCitySchedule } from '../../../../../shared/delivery/utils/delivery.utils';
import moment from 'moment/moment';
import DeliveryAdressFormControl from '../../../../../components/Admin/Delivery/DeliveryAdressFormControl';
import DeliveryDateFormControl from '../../../../../components/Admin/Delivery/DeliveryDateFormControl';
import { addressToDeliveryAddress } from '../../../../../shared/delivery/utils/home/homeDelivery.utils';

export default function HomeDeliveryController({
  lastOrder,
  addresses,
  selectedDeliveryDay,
  selectedDeliveryAddress,
  setSelectedDeliveryDay,
  setSelectedDeliveryAddress,
  setSelectedBillingAddress,
}) {
  const defaultAddress = useMemo(() => {
    if (selectedDeliveryAddress?._id) return addresses.find((address) => address._id === selectedDeliveryAddress._id);
    return addresses.find((address) => address.isMain);
  }, [addresses, selectedDeliveryAddress]);

  const { deliveryCity } = useGetDeliveryCityByZip({ zipCode: defaultAddress.zipCode });
  const deliveryDays = useMemo(() => {
    if (!deliveryCity) return null;

    return getDeliveryCitySchedule({
      schedules: deliveryCity.infos,
      interval: { from: moment(), to: moment().add(14, 'days') },
    });
  }, [deliveryCity]);

  const handleChangeAddress = (value) => {
    const selectedAdress = addresses.find((address) => address._id === value);
    setSelectedDeliveryAddress(selectedAdress ? addressToDeliveryAddress(selectedAdress) : null);
    setSelectedBillingAddress(selectedAdress ? addressToDeliveryAddress(selectedAdress) : null);
  };

  useEffect(() => {
    if (!selectedDeliveryAddress && defaultAddress) {
      const deliveryAddress = addressToDeliveryAddress(defaultAddress);
      setSelectedDeliveryAddress(deliveryAddress);
      setSelectedBillingAddress(deliveryAddress);
    }
  }, [selectedDeliveryAddress, setSelectedDeliveryAddress, setSelectedBillingAddress, defaultAddress]);

  useEffect(() => {
    if (deliveryDays) setSelectedDeliveryDay(deliveryDays[0]);
  }, [deliveryDays, setSelectedDeliveryDay]);

  useEffect(() => {
    if (lastOrder) {
      setSelectedDeliveryAddress(lastOrder.deliveryInfos.deliveryAddress);
      setSelectedBillingAddress(lastOrder.deliveryInfos.deliveryBillingAddress);
    }
  }, [lastOrder, setSelectedDeliveryAddress, setSelectedBillingAddress]);

  return (
    <>
      <DeliveryAdressFormControl
        adresses={addresses}
        deliveryAddressId={defaultAddress._id}
        handleChangeAddress={handleChangeAddress}
      />

      {deliveryDays && selectedDeliveryAddress && (
        <DeliveryDateFormControl
          deliveryDays={deliveryDays}
          selectedDeliveryDay={selectedDeliveryDay}
          setSelectedDeliveryDay={setSelectedDeliveryDay}
        />
      )}
    </>
  );
}
