import React, { Fragment } from 'react';

import moment from 'moment';
import 'moment/locale/fr';

// # UTILS
import {
  Chip,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Button,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import currencyFormater from '../../utils/currencyFormater';
import { calcTotalOrder } from '../../utils/totalOrders';
import OrderItemsTable from '../Admin/Order/Table/OrderItemsTable';

// # MATERIAL UI

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    overflow: 'auto',
    marginBottom: '1rem',
  },
  btnContainer: {
    '& > button': {
      margin: '0 1rem 1rem 0',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexChip: {
    display: 'flex',
    '& > div:nth-child(1)': {
      marginRight: '1rem',
    },
  },
}));

function OrderInfos(props) {
  const total = calcTotalOrder(props.order);

  return (
    <Paper className={props.paper}>
      <Grid item xs={12}>
        <h3>Informations sur la commande :</h3>
        <div className={props.flexChip}>
          <Chip
            label={
              props.order.status === 'paid'
                ? 'Payé'
                : props.order.status === 'waiting'
                ? 'En attente de paiement'
                : props.order.status === 'canceled'
                ? 'Annulé'
                : props.order.status === 'refund'
                ? 'Remboursé'
                : 'Impayé'
            }
            color={
              props.order.status === 'paid' ? 'primary' : props.order.status === 'refuse' ? 'secondary' : 'default'
            }
          />
          <Chip
            label={
              props.order.advStatus === 'input'
                ? 'En cours de saisie'
                : props.order.advStatus === 'validate'
                ? 'Validée'
                : props.order.advStatus === 'processed'
                ? 'Traitée'
                : props.order.advStatus === 'archived'
                ? 'Archivée'
                : 'Annulée'
            }
            color={
              props.order.advStatus === 'input'
                ? 'default'
                : props.order.advStatus === 'canceled'
                ? 'secondary'
                : 'primary'
            }
          />
        </div>
        <p>
          <b>Date de création :</b> {moment(props.order.createdAt).format('DD-MM-YYYY')}
        </p>
        <p>
          <b>Frais de livraison : </b>
          {currencyFormater(props.order.deliveryCost)}
        </p>
        <p>
          <b>Bon de réduction :</b>{' '}
          {props.order.discount
            ? props.order.discount.reduction === 'flat'
              ? currencyFormater(props.order.discount.value)
              : `${props.order.discount.value} %`
            : 'Pas  de bon de réduction'}
        </p>
        <p>
          <b>Nombre de produits :</b> {props.order.totalQuantity}
        </p>
        <div style={{ backgroundColor: '#f7f5dc', borderRadius: '8px', padding: '0.5rem', marginBottom: '1rem' }}>
          <p style={{ margin: 0 }}>
            <b>Montant total :</b> {currencyFormater(total.formatTotalTtc)}
          </p>
          {props.order.partialPayment ? (
            <>
              <p style={{ margin: 0 }}>
                <b>Part payé par Edenred :</b>{' '}
                {currencyFormater(props.order.partialPayment.data.authorizedAmount * 0.01)}
              </p>
              <p style={{ margin: 0 }}>
                <b>Part payé par carte bancaire :</b>{' '}
                {currencyFormater(
                  calcTotalOrder(props.order).formatTotalTtc - props.order.partialPayment.data.authorizedAmount * 0.01
                )}
              </p>
            </>
          ) : (
            <p style={{ margin: 0 }}>
              <b>La totalité a été payé par carte bleue</b>
            </p>
          )}
        </div>
        <Divider />
        <h3>Informations de livraison :</h3>
        <Chip
          label={
            props.order.deliveryInfos.deliveryType === 'home'
              ? 'À domicile'
              : props.order.deliveryInfos.deliveryType === 'relayPoint'
              ? 'Point relais'
              : 'Drive'
          }
          color={
            props.order.deliveryInfos.deliveryType === 'home'
              ? 'primary'
              : props.order.deliveryInfos.deliveryType === 'relayPoint'
              ? 'secondary'
              : 'default'
          }
        />
        <p>
          <b>Date de livraison :</b> {moment(props.order.deliveryInfos.deliveryDate).format('dddd Do MMMM YYYY')}
        </p>
        <p>
          <b>Horaires de livraison :</b> {props.order.deliveryInfos.deliverySchedule}
        </p>
        {props.order.deliveryInfos.deliveryAddress.title ? (
          <p>
            <b>Point de livraison : </b> {props.order.deliveryInfos.deliveryAddress.title}
          </p>
        ) : null}
        <p>
          <b>Adresse de livraison :</b>
          <br />
          {props.order.deliveryInfos.deliveryAddress.address}, {props.order.deliveryInfos.deliveryAddress.zipCode},{' '}
          {props.order.deliveryInfos.deliveryAddress.city}, {props.order.deliveryInfos.deliveryAddress.country}
        </p>
        {props.order.invitation && (
          <>
            <p>
              <b>Chez l'Eco-Acteur : </b>
              <Link href={`/admin/users/${props.order.invitation.sender._id}`}>
                {props.order.invitation.sender.fullname}
              </Link>
            </p>
            <Link href={`/admin/invitation/${props.order.invitation._id}`}>
              <Button variant={'contained'} color={'primary'}>
                Voir l'invitation
              </Button>
            </Link>
          </>
        )}
        <p>
          <b>Complément d'adresse :</b>
          <br />
          {props.order.deliveryInfos.deliveryAddress.complement}
        </p>
        <p>
          <b>Commentaire pour livreur :</b>
          <br />
          {props.order.deliveryInfos.deliveryAddress.comment}
        </p>
        {props.order.deliveryInfos.deliveryService && (
          <p>
            <b>Service de livraison : </b>
            <Link href={`/admin/deliveryServices/${props.order.deliveryInfos.deliveryService._id}`}>
              {props.order.deliveryInfos.deliveryService.name}
            </Link>
          </p>
        )}
      </Grid>
    </Paper>
  );
}

function OwnerInfos(props) {
  return (
    <Paper className={props.paper}>
      <h3>Informations sur l'acheteur :</h3>
      <p>
        <b>Identité : </b>
        <Link href={`/admin/users/${props.owner._id}`}>{props.owner.fullname}</Link>
      </p>
      <p>
        <b>Complément d'information :</b> {props.owner.information}
      </p>
      <p>
        <b>Email :</b> <a href={`mailto:${props.owner.email}`}>{props.owner.email}</a>
      </p>
      <p>
        <b>Numéro de téléphone :</b> {props.owner.phoneNumber}
      </p>
      <Divider />
      <h3>Informations de facturation :</h3>
      <p>
        <b>Adresse de facturation :</b>
        <br />
        {props.deliveryBillingAddress.address}, {props.deliveryBillingAddress.zipCode},{' '}
        {props.deliveryBillingAddress.city}, {props.deliveryBillingAddress.country}
      </p>
      <p>
        <b>Complément d'adresse :</b>
        <br />
        {props.deliveryBillingAddress.complement}
      </p>
    </Paper>
  );
}

function OrderProductsInfos(props) {
  return (
    <Paper className={props.classes.paper}>
      <div className={props.classes.container}>
        <div>
          <h3>Détails de la commande :</h3>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table className={props.classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Image</TableCell>
              <TableCell align="left">Nom</TableCell>
              <TableCell align="left">Catégorie</TableCell>
              <TableCell align="center">Taille</TableCell>
              <TableCell align="center">Prix</TableCell>
              <TableCell align="center">Quantité</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map((item) => (
              <TableRow key={item._id}>
                <TableCell align="left">
                  <img
                    src={
                      item.productId
                        ? `${process.env.REACT_APP_AWS}/${item.photo}`
                        : `https://via.placeholder.com/150/FFFFFF/000000?text=${item.name}`
                    }
                    alt={item.productId ? `product-${item.productId}` : 'Hors catalogue'}
                    width={50}
                    height={50}
                  />
                </TableCell>
                <TableCell align="left">{item.name}</TableCell>
                <TableCell align="left">{item.category}</TableCell>
                <TableCell align="center">
                  <Chip label={item.size} color="secondary" />
                </TableCell>
                <TableCell align="center">{item.price} €</TableCell>
                <TableCell align="center">{item.quantity}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell />
              <TableCell />
              <TableCell />
              <TableCell align="center">
                <b>Total</b>
              </TableCell>
              <TableCell align="center">
                <b>
                  {Math.round(
                    (props.items.reduce((acc, curr) => acc + curr.quantity * curr.price, 0) + Number.EPSILON) * 100
                  ) / 100}{' '}
                  €
                </b>
              </TableCell>
              <TableCell align="center">
                <b>{props.items.reduce((acc, curr) => acc + curr.quantity, 0)}</b>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default function OrderDetails(props) {
  const { order, methods } = props;
  const { owner, items } = order;

  const classes = useStyles();

  return (
    <>
      <Grid item xs={6}>
        <OrderInfos order={order} items={items} paper={classes.paper} flexChip={classes.flexChip} />
      </Grid>
      <Grid item xs={6}>
        <div className={classes.btnContainer}>
          {(order.advStatus === 'input' || order.advStatus === 'canceled') && (
            <Button variant="contained" color="primary" onClick={() => methods.handleChangeAdv('validate')}>
              Valider la commande
            </Button>
          )}

          {order.advStatus === 'validate' && (
            <Button variant="contained" color="primary" onClick={() => methods.handleChangeAdv('processed')}>
              Traiter la commande
            </Button>
          )}

          {order.advStatus === 'processed' && (
            <Button variant="contained" color="primary" onClick={() => methods.handleChangeAdv('archived')}>
              Archiver la commande
            </Button>
          )}

          {order.advStatus === 'archived' && (
            <Button variant="contained" color="secondary" onClick={() => methods.handleChangeAdv('canceled')}>
              Annuler la commande
            </Button>
          )}

          {order.advStatus === 'archived' ? (
            <Button variant="contained" color="primary" onClick={() => methods.handleGetLabels()}>
              Imprimer les étiquettes
            </Button>
          ) : (
            <Button variant="contained" color="primary" onClick={() => methods.handleArchiveAndPrint()}>
              Archiver et imprimer les étiquettes
            </Button>
          )}
        </div>
        <OwnerInfos
          deliveryBillingAddress={order.deliveryInfos.deliveryBillingAddress}
          owner={owner}
          paper={classes.paper}
        />
      </Grid>
      <Grid item xs={12}>
        <OrderItemsTable items={items} />
      </Grid>
    </>
  );
}
