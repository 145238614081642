import React, { useEffect,useMemo } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Divider, Paper } from '@material-ui/core';

import HomeDeliveryController from './DeliveryTypeControllers/HomeDeliveryController';
import RelayDeliveryController from './DeliveryTypeControllers/RelayDeliveryController';
import StoreDeliveryController from './DeliveryTypeControllers/StoreDeliveryController';

import DeliveryTypeFormControl from '../../../../components/Admin/Delivery/DeliveryTypeFormControl';
import UpdateOrderAdressForm from '../../../../components/Order/Form/UpdateOrderAdressForm';
import { STORE_DELIVERY_ADDRESS } from '../../../../shared/delivery/constants/store/store.constants';
import OrderDeliverySummary from '../../../../components/Order/Delivery/OrderDeliverySummary';
import { deliveryTypeToLabel } from '../../../../shared/delivery/utils/delivery.utils';
import { Event as EventIcon, LocalShipping as LocalShippingIcon, Schedule as ScheduleIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    overflow: 'auto',

    '& > h3': {
      margin: '0 0 1rem 0',
    },

    '& > section:first-of-type': {
      display: 'grid',
      gridTemplateColumns: '1.5fr 3fr 3fr',
      gap: '1rem',
      marginBottom: '1rem',
    },
  },

  deliveryAdress: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    gap: '1rem',
    marginBottom: '1rem',

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      width: '350px',
      gap: '1rem',

      '& > h4': {
        margin: '0',
      },
    },
  },
}));

export default function DeliveryInfoSummary({
  lastOrder,
  addresses,
  selectedDeliveryType,
  setSelectedDeliveryType,
  selectedDeliveryAddress,
  selectedBillingAddress,
  setSelectedDeliveryAddress,
  setSelectedBillingAddress,
  selectedDeliveryDay,
  setSelectedDeliveryDay,
}) {
  const classes = useStyles();

  const headers = useMemo(() => {
    if (!selectedDeliveryDay || !selectedDeliveryType) return null;

    return [
      {
        key: 'deliveryType',
        label: 'Type de livraison',
        value: deliveryTypeToLabel(selectedDeliveryType),
        Icon: <LocalShippingIcon />,
      },
      {
        key: 'deliveryDate',
        label: 'Date de livraison',
        value: selectedDeliveryDay.formattedDate,
        Icon: <EventIcon />,
      },
      {
        key: 'deliverySchedule',
        label: 'Horaire de livraison',
        value: selectedDeliveryDay.schedule,
        Icon: <ScheduleIcon />,
      },
    ];
  }, [selectedDeliveryDay]);

  const handleChangeDeliveryType = (event) => {
    const { value } = event.target;
    const isStoreDelivery = value === 'bioculture';
    setSelectedDeliveryType(value);
    setSelectedDeliveryAddress(isStoreDelivery ? STORE_DELIVERY_ADDRESS : null);
    setSelectedBillingAddress(null);
    setSelectedDeliveryDay(null);
  };

  useEffect(() => {
    if (lastOrder) setSelectedDeliveryType(lastOrder.deliveryInfos.deliveryType);
  }, [lastOrder]);

  const lastOrderDeliveryType = lastOrder?.deliveryInfos?.deliveryType;

  return (
    <Paper className={classes.root}>
      <h3>Informations de livraison</h3>
      <section>
        <DeliveryTypeFormControl
          selectedDeliveryType={selectedDeliveryType}
          handleChangeDeliveryType={handleChangeDeliveryType}
        />

        {selectedDeliveryType === 'home' && (
          <>
            {Boolean(addresses.length) && (
              <HomeDeliveryController
                lastOrder={lastOrderDeliveryType === 'home' && lastOrder}
                addresses={addresses}
                selectedDeliveryAddress={selectedDeliveryAddress}
                selectedDeliveryDay={selectedDeliveryDay}
                setSelectedDeliveryDay={setSelectedDeliveryDay}
                setSelectedDeliveryAddress={setSelectedDeliveryAddress}
                setSelectedBillingAddress={setSelectedBillingAddress}
              />
            )}
            {!Boolean(addresses.length) && <p>Aucune adresse pour cet utilisateur</p>}
          </>
        )}
        {selectedDeliveryType === 'relayPoint' && (
          <RelayDeliveryController
            lastOrder={lastOrderDeliveryType === 'relayPoint' && lastOrder}
            selectedDeliveryAddress={selectedDeliveryAddress}
            selectedDeliveryDay={selectedDeliveryDay}
            setSelectedDeliveryDay={setSelectedDeliveryDay}
            setSelectedDeliveryAddress={setSelectedDeliveryAddress}
            setSelectedBillingAddress={setSelectedBillingAddress}
          />
        )}
        {selectedDeliveryType === 'bioculture' && (
          <StoreDeliveryController
            selectedDeliveryDay={selectedDeliveryDay}
            setSelectedDeliveryDay={setSelectedDeliveryDay}
          />
        )}
      </section>
      {!!(headers && selectedDeliveryAddress) && (
        <>
          <Divider style={{ marginBottom: '1rem' }} light />
          <OrderDeliverySummary headers={headers} />

          <section className={classes.deliveryAdress}>
            <div>
              <h4>Adresse de livraison :</h4>
              <UpdateOrderAdressForm
                isEditable={selectedDeliveryType !== 'bioculture'}
                selectedAddress={selectedDeliveryAddress}
                setSelectedAddress={setSelectedDeliveryAddress}
              />
            </div>
            <div>
              <h4>Adresse de facturation :</h4>
              <UpdateOrderAdressForm
                selectedAddress={selectedBillingAddress}
                setSelectedAddress={setSelectedBillingAddress}
              />
            </div>
          </section>
        </>
      )}
    </Paper>
  );
}
