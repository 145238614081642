import React from 'react';
import OrderStatCard from '../../../../components/Order/Card/OrderStatCard';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(calc(120px + 2rem), max-content))',
    gap: '0.5rem',
  },
});

export default function OrderStatCardsContainer({ stats }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {stats.map((stat) => (
        <OrderStatCard key={stat.key} stat={stat} />
      ))}
    </div>
  );
}
