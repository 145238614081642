import React from 'react';

import { Container } from '@material-ui/core';

import SalesPerformanceComparison from '../../../components/Admin/Dashboard/SalesPerformanceComparison';
import { makeStyles } from '@material-ui/core/styles';
import TodaySalesPerformance from '../../../components/Admin/Dashboard/LastOrdersTimeline';
import LastOrdersTimeline from '../../../components/Admin/Dashboard/LastOrdersTimeline';

const useStyles = makeStyles({
  root: {
    '& > h3': {
      margin: '0 0 0.5rem 0',
    },
  },

  firstRow: {
    display: 'flex',
    gap: '1rem',

    '& > :first-child': {
      flex: 4,
    },
    '& > :nth-child(2)': {
      flex: 1,
    },
  },
});

export default function AdminDashboardPage() {
  const classes = useStyles();

  return (
    <Container disableGutters={true} className={classes.root}>
      <h3>Dashboard</h3>
      <section className={classes.firstRow}>
        <section>
          <SalesPerformanceComparison />
        </section>
        <section>
          <LastOrdersTimeline />
        </section>
      </section>
    </Container>
  );
}
