import React from 'react';
import HomeDeliveryNoteSection from './HomeDeliveryNoteSection';

export default function HomeDeliveryNoteTable({ deliveries }) {
  const deliveriesCount = deliveries.length ?? 0;
  const isEmpty = deliveriesCount === 0;

  return (
    <div>
      <h3>Livraisons à domicile : (Total : {deliveriesCount})</h3>
      {isEmpty && <p>Aucune livraison à domicile</p>}
      {!isEmpty && deliveries.map((delivery) => <HomeDeliveryNoteSection key={delivery.id} delivery={delivery} />)}
    </div>
  );
}
