import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import DeliveryNoteHeader from '../../DeliveryNoteHeader';

export default function EcoDeliverySectionHeader({ ordersLen, invitation }) {
  const { city, zipCode, deliveryDate, deliverySchedule, address, complement, comment, labelsLen } = invitation;
  const { fullname, phone } = invitation.sender;

  return (
    <DeliveryNoteHeader
      FirstSection={<FirstSection params={{ ordersLen, city, zipCode, deliveryDate, deliverySchedule }} />}
      SecondSection={<SecondSection params={{ fullname, address, city, zipCode, phone, complement, comment }} />}
      ThirdSection={<ThirdSection params={{ labelsLen }} />}
    />
  );
}
