import React, { Fragment, useState, useEffect } from 'react';
import { axiosGetRequest, axiosPostRequest } from '../../../utils/axiosRequests';
import moment from 'moment';

// MATERIAL UI
import {
  Paper,
  Button,
  TextField,
  Grid,
  TableHead,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Chip,
  Link,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { makeStyles } from '@material-ui/core/styles';
import { calcTotalOrder, totalPrice } from '../../../utils/totalOrders';
import { ADMIN_ORDER_APP_PATHS } from '../../../shared/order/constants/path.constants';

const useStyle = makeStyles({
  paper: {
    padding: '1rem',
  },
});

export default function deliveryServicesDetails() {
  const { id } = useParams();
  const classes = useStyle();
  const jwt = Cookies.get('jwt');
  console.log(jwt);
  const [state, setState] = useState({
    deliveryService: null,
    date: moment().format('YYYY-MM-DD'),
    orders: [],
  });

  useEffect(() => {
    axiosGetRequest(`/deliveryServices/${id}`, jwt)
      .then((res) => setState((prev) => ({ ...prev, deliveryService: res.data.data.deliveryService })))
      .catch((err) => console.log(err));
    axiosGetRequest(`/orders/deliveryService/${id}`, jwt)
      .then((res) => setState((prev) => ({ ...prev, orders: res.data.data.orders })))
      .catch((err) => console.log(err));
  }, []);

  const handleCSV = () => {
    const { id } = state.deliveryService;
    axiosPostRequest(`/orders/csv/${moment(state.date).format('YYYY-MM-DD')}`, jwt, { id })
      .then((res) => {
        const { data } = res.data;
        for (const key in data) {
          const element = data[key];
          const fileURL = URL.createObjectURL(new Blob([element], { type: 'text/csv' }));
          const link = document.createElement('a');
          link.href = fileURL;
          link.setAttribute('download', `export-cmd-${key}-${state.date}.csv`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    e.persist();
    setState((prev) => ({ ...prev, date: e.target.value }));
  };

  return (
    <Fragment>
      <h1>Delivery Services Details</h1>

      <Paper className={classes.paper}>
        <Grid container spacing={3} justifyContent={'flex-end'}>
          <Grid item xs={3}>
            <TextField value={state.date} onChange={handleChange} fullWidth type={'date'} />
          </Grid>
          <Grid item xs={2}>
            <Button fullWidth variant={'contained'} color={'primary'} onClick={handleCSV}>
              CSV
            </Button>
          </Grid>
        </Grid>

        <h2>{state.deliveryService?.name}</h2>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Statut</TableCell>
              <TableCell>ADV</TableCell>
              <TableCell>Montant Payé</TableCell>
              <TableCell>Date de livraison</TableCell>
              <TableCell>Date de création</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.orders.map((order, idx) => (
              <TableRow key={idx}>
                <TableCell>
                  <Link href={ADMIN_ORDER_APP_PATHS.ORDER_SUMMARY.replace(':orderId', order._id)}>
                    {order._id.slice(-5)}
                  </Link>
                </TableCell>
                <TableCell>
                  <Link href={`/admin/users/${order.owner._id}`}>
                    {order.owner.firstname} {order.owner.lastname}
                  </Link>
                </TableCell>
                <TableCell>
                  <Chip color={order.status === 'archived' ? 'primary' : 'secondary'} label={order.status} />
                </TableCell>
                <TableCell>
                  <Chip color={order.advStatus === 'archived' ? 'primary' : 'secondary'} label={order.advStatus} />
                </TableCell>
                <TableCell>{totalPrice(order)}</TableCell>
                <TableCell>{moment(order.deliveryInfos.deliveryDate).format('DD-MM-YYYY')}</TableCell>
                <TableCell>{moment(order.createdAt).format('DD-MM-YYYY')}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Fragment>
  );
}
