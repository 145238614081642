import React from 'react';

import AppRoute from '../../shared/routes/AppRoute';
import Back from '../../layouts/Back';
import { ADMIN_ORDER_APP_PATHS, ADMIN_ORDER_APP_ROOT } from '../../shared/order/constants/path.constants';
import InfosOfTheDay from '../../views/Back/Order/InfosOfTheDay';
import OrderPage from '../../views/Back/Order/OrderPage';
import OrderList from '../../views/Back/Order/OrderList';
import AdminArchiveOrderPage from '../../views/admin/orders/to-archive/Page';
import AdminOrdersDeliveryNotesPage from '../../views/admin/orders/delivery-notes/Page';

export default function AdminOrdersRoutes() {
  return (
    <>
      <AppRoute exact path={ADMIN_ORDER_APP_ROOT} layout={Back} component={OrderList} />
      <AppRoute exact path={ADMIN_ORDER_APP_PATHS.ORDER_SUMMARY} layout={Back} component={OrderPage} />
      <AppRoute exact path={ADMIN_ORDER_APP_PATHS.ARCHIVE_ORDER} layout={Back} component={AdminArchiveOrderPage} />
      <AppRoute
        exact
        path={ADMIN_ORDER_APP_PATHS.DELIVERY_NOTES}
        layout={Back}
        component={AdminOrdersDeliveryNotesPage}
      />
      <AppRoute exact path="/admin/orders/infos" layout={Back} component={InfosOfTheDay} />
    </>
  );
}
